import { publishBulkContent } from "helpers/API Calls/Project/Publish/Publish";
import { useState } from "react";

function usePublishActions({ publishItems, setIsUpdated, closeModal, inputRef, setSelectedItems }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const handlePublish = async () => {
      setIsSubmitting(true);
      const reqData = publishItems[0].activities[0].publish_contents.filter(item => (publishItems.some(publishItem => publishItem.id === item.project_integration_id)))
      const response = await publishBulkContent({ publish: reqData.map(item => ({publish_content_id : item.id}))});
      closeModal();
      setIsSubmitting(false);
      if (response?.success) {
        setIsUpdated(true);
        setSelectedItems([])
        return response.data.map((item, index) => ({...item, post: publishItems.find(item => item.id === reqData[index].project_integration_id)}))
      }else{
        return []
      }
    };
  
    const handleSchedule = async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      // console.log({ publish_content_id: selectedPlatform?.id, dateTime: inputRef.current.value })
      // const response = await schedulePost({ publish_content_id: selectedPlatform?.id, dateTime: innerRef.current.value });
      // if (response?.success) setIsUpdated(true);
      closeModal();
      setIsSubmitting(false);
    };
  
    return { handlePublish, handleSchedule, isSubmitting, setIsSubmitting };
  }
  
export default usePublishActions; 