export const url = "https://api.stage.contentpro.app/api/";

const request = async (requestConfig: any) => {
  try {
    let reqBody : any = {
      method: requestConfig.method ? requestConfig.method : "GET",
      headers: requestConfig.headers ? requestConfig.headers : {},
      body: requestConfig.body ? requestConfig.body : null,
      
    }
    if(requestConfig.credentials){
      reqBody = {...reqBody, credentials: requestConfig.credentials}
    }
    // console.log(reqBody); 

    const response = await fetch(url + requestConfig.url, reqBody);

    const data = await response.json();
    // console.log("data from backend -- request helper function")
    // console.log(data)

    if (!response.ok) {
      let errorMessage: string;
      switch (response.status) {
        case 500:
          errorMessage = response.statusText || "Internal Server Error"; //server errors
          break;
        case 401:
          errorMessage = (data.error && formatValidationErrors(data.error)) || data.message || "Unauthenticated."; //auth error
          break;
        case 404:
          errorMessage = data.message || response.statusText; //not found
          break;
        case 422:
          errorMessage = formatValidationErrors(data.error); //validation error
          break;
        default:
          errorMessage = data.message || "Something went wrong."; //fallback 
      }
      throw new Error(errorMessage);
    }

    return data;
  } catch (error: any) {
    throw new Error(error.message || "Something went wrong."); //fallback
  }
};

const formatValidationErrors = (error: { [key: string]: string[] }) => {
  let errorMessage = "";
  
  for (const key in error) {
    if (error[key] && error[key].length > 0) {
      // Append the field/key name followed by each error message on a new line
      errorMessage += `\n`;
      errorMessage += `${error[key].map((msg) => `${msg}`).join("\n")}\n\n`;
    }
  }
  
  return errorMessage.trim(); // Remove trailing newlines
};


export default request;

