import React, { useEffect, useState } from "react";
import Column from "./Column";
import { Col, Nav, NavItem, NavLink, Row, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import { getActivityStates } from "helpers/API Calls/Project/Settings/Activity State";
import { deleteActivity, editActivity, getActivities } from "helpers/API Calls/Project/Activity/Activity";
import { getActivityTypes } from "helpers/API Calls/Project/Settings/Activity Type";
import { getTeams } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";
import { LinkWithQuery } from "helpers/link_helper";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker } from 'rsuite';
import classnames from 'classnames';

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({
    user: user.user,
    permissions: user.permissions,
  })
);

interface Task {
  id: number;
  title: string;
  type: string;
  typeId: number;
  typeColor: string;
  creator: string;
  creatorId: number;
  team: string;
  teamId: number;
  state: string;
  stateId: number;
  stateColor: string;
  priority: string;
  status: string;
  dueAt: string;
  createdAt: string;
  updatedAt: string;
  columnId: number;
}
interface Columns {
  id: number;
  title: string;
  color: string;
}

const KanbanBoard: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const projectId = searchParams.get("projectId");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]); // New state for filtered tasks
  const [columns, setColumns] = useState<Columns[]>([]);
  const [filterData, setFilterData] = useState<any>({ creators: [], types: [], states: [], teams: [] });
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({});
  const [search, setSearch] = useState("");

  const handleFilterChange = (key: string, value: any) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {

      const states = await getActivityStates(projectId);
      if (states) {
        setColumns(states.map((item) => ({id: item.id, title: item.state_name, color: item.color, })) );
      } else {
        setColumns([]);
      }

      const activities = await getActivities(projectId);
      if (activities) {
        const formattedTasks = activities.map((item) => ({
          id: item.id,
          title: item.title,
          type: item.activity_type,
          typeId: item.activity_type_id,
          typeColor: item.activity_types.color,
          creator: item.creator_name,
          creatorId: item.creator_id,
          team: item.team_name,
          teamId: item.team_id,
          state: item.state_name,
          stateId: item.activity_state_id,
          stateColor: item.activity_state.color,
          priority: item.priority_name,
          status: item.status,
          dueAt: item.due_date,
          createdAt: item.created_at,
          updatedAt: item.updated_at,
          columnId: item.activity_state_id,
        }));
        setTasks(formattedTasks);
        setFilteredTasks(formattedTasks);
      } else {
        setTasks([]);
        setFilteredTasks([]);
      }

      // fetching filter data
      const types = await getActivityTypes(projectId);
      const teams = await getTeams(businessId, `${user.user_type === 'Member' ? `user_id=${user.id}` : ''}`);
      
      const uniqueCreators = new Set();
      const teamMembers = teams?.reduce((acc:any, team:any) => {
        team.members.forEach(member => {
          if (!uniqueCreators.has(member.id)) {
            uniqueCreators.add(member.id);
            acc.push({ label: member.name, value: member.id });
          }
        });
        return acc;
      }, [] as any[]) || [];

      setFilterData({
        types: types?.map((item) => ({ label: item.activity_type, value: item.id })) || [],
        states: states?.map((item) => ({ label: item.state_name, value: item.id })) || [],
        creators: teamMembers,
        teams: teams?.map((item) => ({ label: item.team_name, value: item.id })) || [],
      });

      setIsLoading(false);
    };
    fetchData();
  }, [projectId, businessId,]);

  useEffect(() => {
    const applyFilters = () => {
      const filtered = tasks.filter((task) => {
        const taskCreatedAt = new Date(task.createdAt); // Convert task's createdAt to a Date object
  
        const isWithinDateRange =
          (!filterValues.from_date || taskCreatedAt >= new Date(filterValues.from_date)) &&
          (!filterValues.to_date || taskCreatedAt <= new Date(filterValues.to_date));
  
        return (
          (!filterValues.creators || task.creatorId === parseInt(filterValues.creators)) &&
          (!filterValues.teams || task.teamId === parseInt(filterValues.teams)) &&
          (!filterValues.states || task.stateId === parseInt(filterValues.states)) &&
          (!filterValues.types || task.typeId === parseInt(filterValues.types)) &&
          isWithinDateRange // Apply the date range filter
        );
      });
      setFilteredTasks(filtered); // Update filteredTasks state
    };
  
    applyFilters();
  }, [filterValues, tasks]);
  

  const handleSearch = () => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = tasks.filter((task) => task.title.toLowerCase().includes(lowercasedSearch));
    setFilteredTasks(filtered);
  };
  
  const handleClearSearch = () => {
    setSearch("");
    setFilteredTasks(tasks); // Reset to all tasks
  };
  
  const moveTask = async (taskId: number, toColumnId: number) => {
    const data = {
      edit_type: "activity",
      project_id: projectId,
      activity_state_id: toColumnId,
    };
  
    const response = await editActivity(data, taskId);
    
    if (response?.success) {
      const targetColumn = columns.find((column) => column.id === toColumnId); // Find the target column by its id
      if (targetColumn) {
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === taskId
              ? { ...task, columnId: toColumnId, stateColor: targetColumn.color } // Update task's columnId and stateColor
              : task
          )
        );
      }
    }
  };

  const deleteTask = async (taskId: number) => {
    if(taskId){
      const response = await deleteActivity(taskId);
      if(response){
        setTasks(prev => prev.filter(item => item.id !== taskId))
      }
    }
  }

  
  type DateRange = [Date, Date] | null;

  const [dateRange, setDateRange] = useState<DateRange>(null);
  
  const handleDateRangeChange = (value: [Date | null, Date | null] | null) => {
    if (value && value[0] && value[1]) {
      setDateRange([value[0], value[1]]);
      handleFilterChange('from_date', value[0].toISOString().split('T')[0]);
      handleFilterChange('to_date', value[1].toISOString().split('T')[0]);
    } else {
      setDateRange(null);
      handleFilterChange('from_date', '');
      handleFilterChange('to_date', '');
    }
  };
  
  
  const { user, permissions} = useSelector(selectProperties);
  const canAdd = user?.user_type === 'Member' ? permissions?.includes('create_post') : true;

  return isLoading ? (
    <div className="text-center">
      <Spinner color="primary" />
    </div>
  ) : (
    <div className="card">
    <div className="overflow-auto card-body">

      {/* title */}
      <div className="d-flex align-items-center mb-4 mx-auto">
        <h3 className="mb-0 flex-grow-1 ">Activities Board</h3>
        <div className="flex-shrink-0"></div>
      </div>

      {/* search and add new*/}
      <Row>
        <Col sm={4}>
          <form onSubmit={e => { e.preventDefault(); handleSearch(); }} className="app-search mb-2 p-0">
            <div className="form-group m-0">
              <div className="input-group">
                <input
                  type="text"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  className="form-control "
                  placeholder="Search By Activity Title..."
                />
                <div className="input-group-append">
                  <button className="btn btn-soft-success search-button" type="button" onClick={handleSearch}>
                    <i className="mdi mdi-magnify" />
                  </button>
                  {search && <button
                    className="btn btn-soft-danger ms-2"
                    type="button"
                    onClick={handleClearSearch}
                  >
                    <i className="mdi mdi-close" />
                  </button>}
                </div>
              </div>
            </div>
          </form>
        </Col>
        {canAdd && <Col>
          <div className="text-sm-end text-end mb-2">
            <LinkWithQuery to={`/posts-create`} project={true} queries={`return=posts-kanban`} className="btn btn-success btn-rounded btn-secondary"><i className="mdi mdi-plus me-1"></i>Add New Activity</LinkWithQuery>
          </div>
        </Col>}
      </Row>

      {/* filters */}
      <Row className=" mb-4">
        <div className="mt-1 row mx-0 px-0">
          {Object.keys(filterData).map((filterKey) => (
            <Col md={2} sm={4} key={filterKey} className="">
              <div className="mb-2">
                <select className="form-select" value={filterValues[filterKey] || ""} onChange={(e) => handleFilterChange(filterKey, e.target.value)} >
                  <option value="">
                    {filterKey === "creators"
                      ? "All Creators"
                      : filterKey === "types"
                      ? "All Types"
                      : filterKey === "states"
                      ? "All States"
                      : "All Teams"}
                  </option>
                  {filterData[filterKey]?.map((option: any) => (
                    <option value={option.value} key={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          ))}
          <Col md={2} sm={4} >
            <DateRangePicker
              placeholder="Select Date"
              value={dateRange}
              onChange={handleDateRangeChange}
              format="yyyy-MM-dd"
            />
          </Col>
          <Col md={2} sm={4} className="text-end">
            <button type="button" className="btn btn-soft-success" title="Activities List">
              <LinkWithQuery to={`/posts-list`} project={true} className="px-3 py-2 text-success"><i className="mdi mdi-table"/></LinkWithQuery>
            </button>
          </Col>
        </div>
      </Row>

      <Row className="flex-nowrap">
        {columns.map((column) => (
          <Column
            key={column.id}
            column={column}
            tasks={filteredTasks.filter((task) => task.columnId === column.id)}
            moveTask={moveTask}
            deleteTask={deleteTask}
          />
        ))}
      </Row>
    </div>
    </div>
  );
};

export default KanbanBoard;
