import { createSlice } from "@reduxjs/toolkit";
import { getActivityState } from "./thunk";

export const initialState = {
    activityStateList: [], 
    loading: true,
    pagination: {current_page : 1, per_page : 10,}
}

const ActivityStateSlice = createSlice({
    name: "ActivityStateSlice", 
    initialState, 
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(getActivityState.fulfilled, (state: any, action: any) => {
            state.activityStateList = action.payload.items;
            state.pagination = action.payload.pagination;
            state.loading = false; // Set loading to false when request is done
        });
        builder.addCase(getActivityState.pending, (state: any, action: any) => {
            state.activityStateList = []; // reset any previous data while fetching new data
            state.loading = true; 
        });
        builder.addCase(getActivityState.rejected, (state: any, action: any) => {
            state.activityStateList = []; // reset any previous data
            state.loading = false; 
        });
    }
    
})


export default ActivityStateSlice.reducer
