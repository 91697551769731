import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Button, Spinner, Modal, ModalBody, Form, FormGroup, Label, Input } from "reactstrap";
import SocialIcon from "../../Activity/UI/SocialIcon";
import { getIntegrations, deleteIntegration, editIntegration } from 'helpers/API Calls/Project/Settings/Integrations';
import { useLocation } from 'react-router-dom';
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import CustomIcon from "Components/Custom/CustomIcon";

const ConnectedAccounts = ({refresh, socialPlatforms, OnUpdate}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");

  const [connectedAccounts, setConnectedAccounts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});

  const selectedAccount = connectedAccounts?.find(account => account.id === selectedAccountId);
  const selectedPlatform = socialPlatforms?.find(platform => platform.id === selectedAccount?.integration_type_id)

  useEffect(() => {
    const fetchConnectedAccounts = async () => {
      setIsLoading(true);
      const response = await getIntegrations(projectId);
      if (response) {
        setConnectedAccounts(response);
      }
      setIsLoading(false);
    };

    fetchConnectedAccounts();
  }, [projectId, refresh]);

  const handleOpenModal = (accountId: number) => {
    setSelectedAccountId(accountId);
    setDeleteModal(true);
  };

  const handleDisconnect = async () => {
    if (selectedAccountId) {
      setIsSubmitting(true);
      const response = await deleteIntegration(selectedAccountId);
      if (response) {
        setConnectedAccounts((prevAccounts) =>
          prevAccounts.filter(account => account.id !== selectedAccountId)
        );
      }
      setIsSubmitting(false);
      setDeleteModal(false);
    }
  };

  const onEditClick = (accountId: number) => {
    setSelectedAccountId(accountId);
    setIsModalOpen(true)
  } 

  useEffect(() => {
    if (selectedAccount) {
      const initialFormValues: { [key: string]: string } = {};
      selectedPlatform?.integration_type_fields.forEach((field: any) => {
        initialFormValues[field.mapping_column] = selectedAccount[field.mapping_column] || '';
      });
      setFormValues(initialFormValues);
    }
  }, [selectedAccountId]);
  
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };
  
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = {
      ...formValues, 
      integration_type_id: selectedPlatform.id,
      project_id: projectId,
    }
    const response = await editIntegration(data, selectedAccountId)
    if(response){
      OnUpdate()
    }
    setIsSubmitting(false);
    toggleModal();
  }


  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDisconnect}
        onCloseClick={() => setDeleteModal(false)}
        message={`${selectedAccount?.integration_type?.type_name} account "${selectedAccount?.integration_user}"?`}
      />

      {/* Modal for form */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} centered={true} backdrop={"static"}>
        <div className="modal-content">
          <ModalBody className="px-4 pt-5">
            <button type="button" onClick={toggleModal} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 text-center font-size-20 text-primary">Connect {selectedPlatform?.type_name} Account</div>
            {selectedPlatform && (
              <Form onSubmit={handleSubmit}>
                {selectedPlatform.integration_type_fields.map((field: any) => (
                  <FormGroup key={field.id}>
                    <Label for={field.mapping_column}>{field.field_name}</Label>
                    <Input
                      type={field.field_datatype === 'string' ? 'text' : field.field_datatype}
                      id={field.mapping_column}
                      name={field.mapping_column}
                      value={formValues[field.mapping_column] || ''}
                      onChange={handleInputChange}
                      required
                      max={35}
                    />
                  </FormGroup>
                ))}
                <div className="hstack gap-2 mb-0 justify-content-end">
                  <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
                  <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Save Changes"}
                  </button>
                </div>
              </Form>
            )}
          </ModalBody>
        </div>
      </Modal>

      <Card className="p-3">
        <CardBody>
          <div className="align-items-center mb-4">
            <h3 className="">Connected social accounts</h3>
            <p className="card-title-desc">View and manage your social accounts in one place.</p>
          </div>

          {isLoading ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <Row>
              {connectedAccounts.length > 0 ? (
                connectedAccounts.map((item) => (
                  <Col md={6} lg={4} key={item.id} className="my-3">
                    <Card className="bg-light h-100">
                      <CardBody className="d-flex flex-column pb-0">
                        <div className="d-flex flex-wrap align-items-start">
                          <SocialIcon 
                            type={item?.integration_type?.type_name} 
                            slug={item?.integration_type?.type_slug} 
                            title={item?.integration_user || item.access_token?.platform_username}
                          />
                          <div className="ms-auto">
                            {!item?.integration_type?.connect_url &&
                              <Button className="btn btn-soft-info btn-sm me-1" onClick={() => onEditClick(item.id)} disabled={isSubmitting}>
                                <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                              </Button>
                            }
                            <Button className="btn btn-soft-danger btn-sm" onClick={() => handleOpenModal(item.id)} disabled={isSubmitting}>
                              <CustomIcon className="mdi " iconName= "mdi-delete-outline" id={`remove-${item.id}`} text="Disconnect" />
                            </Button>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap border-bottom ms-4 mt-3">
                          <div className="my-auto"><span className='ms-3 '>{item?.app_url || item?.access_token?.platform_id}</span></div>
                        </div>
                        {/* <i className="mdi mdi-link ms-3 me-1"></i> */}
                        {/* <div className="d-flex flex-wrap mt-2">
                          <div className="my-auto"><span><i className="mdi mdi-account me-1"></i>{item.integration_user}</span></div>
                        </div> */}
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : (
                <div className="text-center">
                  <p>No connected accounts found.</p>
                </div>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default ConnectedAccounts;
