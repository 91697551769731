export const statusBadge = (status) => {
    switch (status){
      case "Published":
          return <span className="font-size-11 badge badge-soft-success">{status}</span>;
      case "Ready For Publish":
          return <span className="font-size-11 badge badge-soft-primary">{status}</span>;
      case "Scheduled":
          return <span className="font-size-11 badge badge-soft-warning">{status}</span>;
      case "Draft":
          return <span className="font-size-11 badge badge-soft-info">{status}</span>;
      case "Cancelled":
        return <span className="font-size-11 badge badge-soft-danger">{status}</span>;
    case "Failed":
        return <span className="font-size-11 badge badge-soft-danger">{status}</span>;
      default:
          return <span className="font-size-11 badge badge-soft-secondary">Not specified</span>;
    }
  }