import React, { useEffect, useMemo, useState } from "react";
import withRouter from "Components/Common/withRouter";
import GoBackButton from "Components/Common/GoBackButton";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import ActivityDetail from "./Details/ActivityDetail";
import { getActivityDetail, getPriorities } from "helpers/API Calls/Project/Activity/Activity";
import Priority from "./Details/Priority";
import HashTags from "./Details/HashTags";
import ImageGallery from "./Details/ImageGallery";
import AuthorNotes from "./Details/AuthorNotes";
import ActivityFeed from "./Details/ActivityFeed";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUsers } from "helpers/API Calls/Space Settings/Users Settings/Users/users";
import { getActivityStates } from "helpers/API Calls/Project/Settings/Activity State";
import Comments from "./Details/Comments";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({
    user: user.user,
    permissions: user.permissions, 
  })
);

const ProjectsOverview = (props: any) => {
  //meta title
  document.title = "Activity Details | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const projectId = searchParams.get("projectId");
  const returnURL = searchParams.get("return");
  const params = props.router.params;
  const activityId = params.id;

  const { user, permissions } = useSelector(selectProperties);

  const [activityData, setActivityData] = useState<any>({});
  const [priorityOptions, setPriorityOptions] = useState<any>([]); 
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState([]);
  const [activityStates, setActivityStates] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {

      // activity data
      const data = await getActivityDetail(activityId);
      if (data) {setActivityData(data);} 
      else {setActivityData({});}

      // priority
      const priorities = await getPriorities();
      if (priorities) {setPriorityOptions(priorities.map(item => {return {label: item.priority_name, value: item.id}}));} 

      // users
      const users = await getUsers(businessId);
      if (users && users.length > 0) {
        setUsers(users.map((item) => ({ name: item.name, id: item.id })));
      } else {setUsers([]);}

      const stateData = await getActivityStates(projectId); 
      if (stateData && stateData.length > 0) {
        setActivityStates(stateData?.map(item => ({ label: item.state_name, value: item.id, color: item.color })));
      }else{setActivityStates([]); }

      setIsLoading(false);
    };
    getData();
  }, [activityId, businessId, projectId]);

  const canEdit=useMemo(()=>user?.user_type==='Member'?permissions?.includes('edit_post'):true,[user?.user_type,permissions]);
  const canEditState=useMemo(()=>user?.user_type==='Member'?permissions?.includes('edit_post_state'):true,[user?.user_type,permissions]);
  const canArchive=useMemo(()=>user?.user_type==='Member'?permissions?.includes('archive_post'):true,[user?.user_type,permissions]);
  const canUploadFiles=useMemo(()=>user?.user_type==='Member'?permissions?.includes('upload_files'):true,[user?.user_type,permissions]);
  const canManageLogs=useMemo(()=>user?.user_type==='Member'?permissions?.includes('view_activity_logs'):true,[user?.user_type,permissions]);
  const canManageVersions=useMemo(()=>user?.user_type==='Member'?permissions?.includes('view_version_logs'):true,[user?.user_type,permissions]);
  const canPublish=useMemo(()=>user?.user_type==='Member'?(permissions?.includes('publish_post')):true,[user?.user_type,permissions]);
  
  return (
    <React.Fragment>
      <ToastContainer/>
      <div className="page-content">
        <Container fluid>
          <GoBackButton to={`/${returnURL !== null ? returnURL : 'posts-list'}`} project={true}/>
          <Row>
            <Col lg="12">
              {isLoading ? (
                <div className="text-center"><Spinner color="primary" /></div>
              ) : (
                <Card>
                  <CardBody>

                    <ActivityDetail 
                      activityCreatedAt={activityData?.created_at}
                      activityCreatedBy={activityData?.creator_name}
                      state={activityData?.activity_state.state_name}
                      state_color={activityData?.activity_state.color}
                      state_id={activityData?.activity_state.id}
                      type={activityData?.activity_types.activity_type}
                      status={activityData?.status}
                      type_color={activityData?.activity_types.color}
                      title={activityData?.title}
                      content={activityData?.content}
                      contentUpdatedAt={activityData?.content_updated_at}
                      contentUpdatedBy={activityData?.content_updated_by_name}
                      dueDate={activityData?.due_date}
                      team={activityData?.team_name}
                      canEdit={canEdit}
                      canArchive={canArchive}
                      canPublish={canPublish}
                      canManageLogs={canManageLogs}
                      canEditState={canEditState}
                      canManageVersions={canManageVersions}
                      activityStates={activityStates}
                      projectId={projectId}
                      activityId={activityId}
                      queries={`return=${returnURL}`}
                    />

                    <div className="d-md-flex d-sm-block ">
                      {/* <Category/> */}
                      <Priority priorityOptions={priorityOptions} selectedPriority={{label: activityData?.priority_name, value: activityData?.priority_id}} id={activityData?.id} canEdit={canEdit}/>
                      <HashTags tags = {activityData?.activity_tags?.[0]?.tag_name || ''} id={activityData?.id} canEdit={canEdit}/>
                    </div>

                    <Row className="my-4" style={{ overflowX: "auto" }}>
                      <h5 className="fw-bold font-size-21 pb-1">Media</h5>
                      <ImageGallery 
                        files={activityData?.files.map(item => {return {id: item.id, type: item.file_type, file: item.file}})}
                        canUploadFiles={canUploadFiles}
                        activityId = {activityId}
                        projectId={projectId}
                      />
                    </Row>

                    <Row>
                      <AuthorNotes 
                        comments={activityData?.activity_notes.map(item => {return { id: item.id, user: item.user, comment: item.comment, updatedAt: item.updated_at,}})}
                        user= {user}
                        users= {users}
                      />
                    </Row>

                    <Row>
                      <ActivityFeed activity={activityData?.activity_logs.map(item => 
                        ({name:item?.user?.name, timestamp:item.created_at, action:item.content})) || []}
                        returnURL={returnURL}
                      />
                    </Row>

                    <Row>
                      <Comments
                        feed={activityData?.activity_discussions.map(item => {return {id: item.id, user: item.user, comment: item.comment, updatedAt: item.updated_at, action_type: 'message'}})}
                        user= {user}
                        users= {users}
                      />
                    </Row>     
                        
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProjectsOverview);
