import React, { useEffect, useState } from "react";
import { Card, Row, Col,} from "reactstrap";
import CustomIcon from "Components/Custom/CustomIcon";
import { ToastContainer } from "react-toastify";
import {Link} from 'react-router-dom';
import { getSubscriptions } from "slices/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import convertDateFormat from "Components/Custom/DateFormat";
  
// redux code
const selectProperties = createSelector(
  (state: any) => state.billing,
  (billing) => ({
    billing: billing.subscriptionList,
    loading: billing.loading,
    pagination: billing.pagination
  })
);

const head = [
  { id: 1, name: "#", isSortable: true, key: "id" },
  { id: 2, name: "Business", isSortable: true, key: "business_name" },
  { id: 3, name: "Business Owner", isSortable: true, key: "user_name" },
  { id: 4, name: "Plan", isSortable: true, key: "product_name" },
  { id: 5, name: "Price", isSortable: true, key: "amount" },
  { id: 6, name: "Stripe Status", isSortable: true, key: "stripe_status" },
  { id: 8, name: "Created", isSortable: true, key: "created_at" },
  { id: 9, name: "Updated", isSortable: true, key: "updated_at" },
  { id: 10, name: "Actions" },
]

function Subscriptions() {

  document.title = "Subscriptions | Content Pro";

  const { billing, loading, pagination } = useSelector(selectProperties);

  // local state mng for getting business data
  const [billingData, setBillingData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(loading);
    setBillingData(billing);
  }, [billing, loading]);

  return (
  <>
    
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col>
            <Card>
              <PaginatedTable
                tableHead={head}
                onGetData = {getSubscriptions}
                pagination = {pagination}
                isLoading = {isLoading}
                isAddButton = {false} 
                title = 'Subscriptions'
              >
                {billingData.length !== 0 && billingData?.map((item : any, index : any) => (
                  <tr key={item.id + index}>
                    <th scope="row">{item.user_id || 0}</th>
                    <td><h5 className='font-size-14'><Link to={`${item.business_id}`} className='text-dark'>{item.business_name}</Link></h5></td>
                    <td>{item.user_name}</td>
                    <td>{item.product_name || "N/A"}</td>
                    <td>${item.amount || 0}{item.currency?.toUpperCase() || "USD"}/{item.interval || "month"}</td>
                    <td>{item.stripe_status?.charAt(0).toUpperCase() + item.stripe_status?.slice(1) || "N/A"}</td>
                    <td>{convertDateFormat(item.created_at) || "N/A"}</td>
                    <td>{convertDateFormat(item.updated_at) || "N/A"}</td>
                    <td className="d-flex">
                      <Link to={`${item.business_id}`} className="btn btn-sm btn-soft-primary">
                        <CustomIcon className="mdi " iconName= "mdi-eye-outline" id={`view-${item.id}`} text="View"/>
                      </Link>
                    </td>
                  </tr>
                ))}
                {billingData.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
              </PaginatedTable>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </div>
  </>
  )
}

export default Subscriptions