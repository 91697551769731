import { registerUserSuccessful, registerUserFailed, resetRegisterFlagChange, apiErrorChange } from "./reducer";
import request from "helpers/request_helper";
import getFormData from "Components/Custom/Formdata";

export const registerUser = (user: any, history: any) => async (dispatch: any) => {
  // console.log(user)
  try {
    const storedUser = JSON.parse(localStorage.getItem("authUser") || "null");
    const token = localStorage.getItem('token');

    const formData = getFormData(user);

    let args = {
      url: "register",
      method: "POST",
      body: formData,
      headers: {}, 
    };


    if (storedUser && storedUser.role === 'Admin' && token) {
      args = {
      url: "adminRegister",
      method: "POST",
      body: formData,
      headers: {'Authorization' : 'Bearer ' + token}
    };
    }

    const response = await request(args);
    const data = await response;

    if (!storedUser) {
      // console.log("new user");
      localStorage.setItem("token", data.data.token);
      const current_user = data.data;
      const userData = {
        name: current_user.name,
        email: current_user.email,
        role: current_user.user_type,
        business_id: current_user.business_id,
        stripe_id: current_user.stripe_id,
        created_at: current_user.created_at,
      };
      localStorage.setItem("authUser", JSON.stringify(userData));
      dispatch(registerUserSuccessful(userData));
      dispatch(apiErrorChange(""));
      history("/");
    } else {
      // console.log("admin user");
      dispatch(registerUserSuccessful(storedUser));
      dispatch(apiErrorChange(""));
      setTimeout(() => {history("/admin/businesses");}, 2000);
    }

  } catch (error: any) {
    dispatch(registerUserFailed(error.message));
  }
};


export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = () => {
  try {
    const response = apiErrorChange("");
    return response;
  } catch (error) {
    return error;
  }
};
