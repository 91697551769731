import { createSlice } from "@reduxjs/toolkit";
import { getFiles}  from "./thunk";

export const initialState = {
    filesList: [], 
    loading: true,
    pagination: {current_page : 1, per_page : 10,}
}

const FilesSlice = createSlice({
    name: "FilesSlice", 
    initialState, 
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(getFiles.fulfilled, (state: any, action: any) => {
            state.filesList = action.payload.items;
            state.pagination = action.payload.pagination;
            state.loading = false; // Set loading to false when request is done
        });
        builder.addCase(getFiles.pending, (state: any, action: any) => {
            state.filesList = []; // reset any previous data while fetching new data
            state.loading = true; 
        });
        builder.addCase(getFiles.rejected, (state: any, action: any) => {
            state.filesList = []; // reset any previous data
            state.loading = false; 
        });
    }
    
})


export default FilesSlice.reducer
