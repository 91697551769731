import React, { useState } from "react";
import { Table} from "reactstrap";
import DataWrapper from "./DataWrapper";

// sorting value
const toggle = function(sort){ 
  return sort === 'ASC' ? 'DESC' : 'ASC' 
};

function PaginatedTable({ onGetData, pagination, isLoading, children, tableHead, buttonText = "", isAddButton, to='', isDeleted=false, title,hasFilter=false, hasDateFilter=false, filterData = [{}], query ='', isAddModal=false, onClick=()=>{}, isActivityPage=false}) {
  const [sort, setSort] = useState({sort_column: "id" , sort: "DESC"}); 
  const onSortClick = (item) => {
    if (item?.isSortable) {
      const sort_value = (item.key === sort.sort_column) ? toggle(sort.sort) : "ASC";
      setSort({ sort_column: item.key, sort: sort_value });
    }
  };

  return (
    <>    
    <DataWrapper 
      onGetData={onGetData}
      pagination={pagination}
      isLoading={isLoading}
      buttonText={buttonText}
      isAddButton={isAddButton}
      isDeleted={isDeleted}
      title={title}
      sort={sort}
      filters = {filterData}
      hasDateFilter={hasDateFilter}
      hasFilter={hasFilter}
      to= {to}
      query= {query}
      isAddModal={isAddModal}
      onClick={onClick}
      isActivityPage={isActivityPage}
    >
      <TableData tableHead={tableHead} sort={sort} onSortClick={onSortClick}>
        {children}
      </TableData>
    </DataWrapper>
    </>
  );
}

export default PaginatedTable;

const TableData = ({sort, onSortClick, tableHead, children}) => {
return <>
    <div className="table-responsive">
      <Table hover className="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline">
        <thead className="table-light">
          <tr>
            {tableHead.map(item =>  
              <th key={`key_${item.id}`} onClick={() => onSortClick(item)} role={item.isSortable && "button"}>
              <div className="d-flex">
              <span className="my-auto">{item.name}</span>
              {item.isSortable && (
                <button className="sort-indicator my-auto">
                  <span className={`upper mb-1 ${sort.sort_column === item.key && sort.sort === 'ASC' ? 'opacity-100' : 'opacity-25'}`}></span>
                  <span className={`lower ${sort.sort_column === item.key && sort.sort === 'DESC' ? 'opacity-100' : 'opacity-25'}`}></span>
                </button>
              )}
              </div>
            </th>
            )}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </div>
  </>
}