import React from "react";
import { Container } from "reactstrap";
import StateList from "./States/StateList";
import { ToastContainer } from "react-toastify";


const State: React.FC = () => {
  document.title = "States | Content Pro";

  return (
    <div className="page-content">
      <ToastContainer/>
      <Container fluid>
          <StateList />
      </Container>
    </div>
  );
};

export default State;
