import React, { useEffect } from "react";
import withRouter from "Components/Common/withRouter";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Spinner } from "reactstrap";
import { createIntegration } from "helpers/API Calls/Project/Settings/Integrations";

  
  const ConnectTwitter = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const oauth_token = searchParams.get('oauth_token');
    const oauth_verifier = searchParams.get('oauth_verifier');
      
    useEffect(() => {
        const project_id = localStorage.getItem("project_id");
        const integration_type_id = localStorage.getItem("integration_type_id");
        const businessId = localStorage.getItem("businessId");
        async function sendRequest() {
            const reqData = {
                project_id: project_id, 
                integration_type_id: integration_type_id,
                oauth_verifier: oauth_verifier,
                oauth_token: oauth_token,
            }
            const data = await createIntegration(reqData);
            if (data) {
                localStorage.removeItem("project_id");
                localStorage.removeItem("integration_type_id");
                localStorage.removeItem("businessId");
                setTimeout(() => navigate(`/integrations?projectId=${project_id}&businessId=${businessId}`), 5000);
            }
        }
        sendRequest();
    }, [navigate, oauth_token, oauth_token]);

    return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100">
            <div className="text-center">
                <Spinner color="primary" />
                <p className="mt-3">Connecting...</p>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default withRouter(ConnectTwitter);
