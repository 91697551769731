import { toast } from "react-toastify";
import request from "../../../request_helper";

export const getIntegrationTypes = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "integration_types?limit=1000",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getIntegrations = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "project_integrations?limit=1000&project_id=" + id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const createIntegration = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "project_integrations",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : true, 
                'Access-Control-Allow-Origin': 'https://portal.stage.contentpro.app', 
                'cache-control': 'no-cache',
            },
            credentials: 'include',
            body : JSON.stringify(reqData), 
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        toast.success(response.message, { autoClose: 2000 });
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false}
    }
}

export const editIntegration = async (reqData, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "project_integrations/" + id,
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data.success; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const deleteIntegration = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "project_integrations/" + id,
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data.success; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getLinkedInProfiles = async (code) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "get_linkedin_profiles?code=" + code,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        };
        const response = await request(args); 
        const data = await response;
        // toast.success(response.message, { autoClose: 2000 });
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false}
    }
}

export const getTwitterUrl = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "twitter_auth_token",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : true, 
                'Access-Control-Allow-Origin': 'https://portal.stage.contentpro.app', 
                'cache-control': 'no-cache',
            },
            credentials: 'include',
        };
        const response = await request(args); 
        const data = await response;
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false}
    }
}