import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import convertDateFormat from "Components/Custom/DateFormat";
import { getBusinessDetail } from "helpers/API Calls/Super Admin/businesses";
import GoBackButton from "Components/Common/GoBackButton";

function Payments() {
  document.title = "Business Details | Content Pro";
  
  const params = useParams(); 
  const businessId = params.businessId;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [businessData, setBusinessData] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const data = await getBusinessDetail(businessId);
      setBusinessData(data);
      setIsLoading(false);
    };
    getData();
  }, [businessId]);

  return (
   <>
    <div className="page-content">
    <ToastContainer/>
    <Container fluid>
    <GoBackButton to="/admin/businesses" admin={true}/>
    {isLoading ? (
        <Spinner
          color="primary"
          className="position-absolute top-50 start-50"
        />
      ) : (
        businessData ?
        <>
        <Row>

          <Col xl={3}>

            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                        {businessData?.image ? 
                        <img src={businessData?.image} alt="" className="avatar-xs rounded-circle"/>
                        :
                        <div className="avatar-xs">
                            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                            {businessData.business_name?.charAt(0)}
                            </div>
                        </div>
                        }
                    </div>
                  <div className="ms-3 mb-0">
                  <h5>{businessData.business_name}</h5>
                  <p className="m-0 p-0 text-muted">{businessData.business_key}</p>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className="d-flex">
                    <div className="avatar-xs">
                        <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                        <i className="bx bx-user fs-4"></i>
                        </div>
                    </div>
                    <div className="ms-3 mb-0">
                        <h5>{businessData.owner_name}</h5>
                        <p className="m-0 p-0 text-muted">
                            {businessData.owner_email}
                            <span className={`ms-2 badge badge-soft-${businessData.email_verified_at ? 'success' : 'danger'}`}>{businessData.email_verified_at ? "Verified" : 'Not Verified'}</span>
                        </p>
                    </div>
                </div>
              </CardBody>
            </Card> 
          </Col>

          <Col xl={9}>
            <Card>
              <CardBody>
                <h5 className="fw-semibold">Business Details</h5>
            
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                        <tr>
                            <th scope="col">Timezone</th>
                            <td>
                            {!(businessData.timezone) ? (
                                <p className="text-danger mx-auto">Not Selected</p>
                            ) : (
                                <p className="p-0 m-0">
                                <span className="font-size-14 me-1">{businessData.timezone_name}</span>
                                <span className="text-muted">{businessData.timezone}</span>
                                </p>
                            )}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Trial Ends At</th>
                            <td>{convertDateFormat(businessData.trial_ends_at) || 'No Plan'}</td>
                        </tr>
                        <tr>
                            <th scope="row">Subscription Created At</th>
                            <td>{convertDateFormat(businessData.created_at) || 'No Plan'}</td>
                        </tr>
                        <tr>
                            <th scope="row">Last Updated At</th>
                            <td>{convertDateFormat(businessData.updated_at) || 'No Plan'}</td>
                        </tr>
                        <tr>
                            <th scope="row">Access Status</th>
                            <td>
                            <span className={`badge badge-soft-${businessData.status ? 'success' : 'danger'}`}>
                                {businessData.status ? 'Active' : 'In-Active'}
                            </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

              </CardBody>
            </Card>   
          </Col>

        </Row>
        </>
        :
        <Row><div className="text-center text-danger">Sorry! No data found.</div></Row>
      )}
    </Container>
  </div>
   </>
  );
}

export default Payments;
