import React, { useState, useCallback, useEffect, useMemo } from "react";
import State from "./StateItem";
import { createSelector } from "reselect";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, CardBody, Col,Form, FormGroup, Input, Label, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { getActivityStates, createActivityState, deleteActivityState, editActivityState, editActivityStateSequence } from "helpers/API Calls/Project/Settings/Activity State";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import ConformationModal from "Components/Custom/ConformationModal";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({
    permissions: user.permissions,
    user: user.user,
  })
);

const StateList: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");

  const { permissions, user } = useSelector(selectProperties);

  const [statesData, setStatesData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  
  const [modal, setModal] = useState<boolean>(false);
  const [stateData, setStateData] = useState({id: null, state_name: '', color: '#453656'});
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);

  const [UpdateModal, setUpdateModal] = useState<boolean>(false);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const states = await getActivityStates(projectId);
      if (states) {
        setStatesData(states.map((item) => ({ id: item.id, title: item.state_name, color: item.color,})));
      } else {setStatesData([]);}
      setIsLoading(false);
    };
    fetchData();
  }, [projectId, isModified]);

  useEffect(() => {
    if (isModified) {
        setIsModified(false);
    }
  }, [isModified]);

  const moveState = useCallback((dragIndex: number, hoverIndex: number) => {
    setStatesData((prevTasks) => {
      const draggedTask = prevTasks[dragIndex];
      const updatedTasks = [...prevTasks];

      updatedTasks.splice(dragIndex, 1); // Remove the dragged item
      updatedTasks.splice(hoverIndex, 0, draggedTask); // Insert at new position

      setHasUnsavedChanges(true);
      return updatedTasks;
    });
  }, []);

  const saveStateOrder = async () => {
    setIsSubmitting(true); // Show spinner on save
    setUpdateModal(false);
    const data = {sequence: statesData.map(item => ({activity_state_id:item.id}))}
    const response = await editActivityStateSequence(data, projectId);
    if (response) {
      setHasUnsavedChanges(false); // Reset unsaved changes flag
    }
    setIsSubmitting(false);
  };

  // Reset stateData on modal close
  const resetStateData = useCallback(() => {setStateData({id: null,state_name: '',color: '#453656',});}, [])
  const setCurrentState = useCallback((item) => {setStateData({ id: item.id, state_name: item.state_name, color: item.color })},[])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true); // Set submitting state to true

    const data = {
      project_id: projectId, 
      state_name: stateData.state_name, 
      color: stateData.color 
    };

    let response; 
    if (stateData.id) {
      response = await editActivityState(data, stateData.id);
    } else {
      response = await createActivityState(data);
    }
    if(response){
        setIsModified(response)
    }
    setModal(false);
    resetStateData();
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true); // Set submitting state to true

    if (stateData) {
      const response = await deleteActivityState(stateData.id);
      if(response){setIsModified(response)}
      setDeleteModal(false);
      resetStateData();
    }
    setIsSubmitting(false); // Reset submitting state
  };

  const canAdd = useMemo(() => user?.user_type === 'Member' ? permissions?.includes('add_activity_state') : true, [permissions, user]);
  const canEdit = useMemo(() => user?.user_type === 'Member' ? permissions?.includes('edit_activity_state') : true, [permissions, user]);
  const canDelete = useMemo(() => user?.user_type === 'Member' ? permissions?.includes('delete_activity_state') : true, [permissions, user]);

  return (
    <>

    <DeleteModal
      show={deleteModal}
      onDeleteClick={handleDelete}
      onCloseClick={() => { setDeleteModal(false); resetStateData(); }}
      message={`Activity State: "${stateData.state_name}"`}
    />

    <ConformationModal
      show={UpdateModal}
      onConfirmClick={saveStateOrder}
      onCloseClick={() => { setUpdateModal(false);}}
      message={`update state sequence`}
    />

    <Modal isOpen={modal} toggle={() => { setModal(false); resetStateData(); }} centered={true} backdrop={'static'}>
    <div className="modal-content">
        <ModalBody className="px-4 pt-5">
        <button type="button" onClick={() => { setModal(false); resetStateData(); }} className="btn-close position-absolute end-0 top-0 m-3"></button>
        <div className="mb-4 text-center font-size-20 text-primary">{stateData.id ? "Edit" : "Add"} State</div>
        <Form onSubmit={handleSubmit}>
            <FormGroup className="select2-container">
            <Label className="col-form-label">Title</Label>
            <Input
                type="text"
                id="title"
                name="title"
                className="form-control"
                onChange={(e) => setStateData(prev => ({ ...prev, state_name: e.target.value }))}
                value={stateData.state_name}
                required
                disabled={isSubmitting}
                maxLength={22} 
            />
            <Label className="col-form-label">Color</Label>
            <Input
                type="color"
                id="color"
                name="color"
                className="form-control form-control-color w-100"
                onChange={(e) => setStateData(prev => ({ ...prev, color: e.target.value }))}
                value={stateData.color || ""}
                disabled={isSubmitting} // Disable input during submission
            />
            </FormGroup>
            <div className="hstack gap-2 mb-0 justify-content-end">
            <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); resetStateData(); }}>Close</button>
            <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Save"}
            </button>
            </div>
        </Form>
        </ModalBody>
    </div>
    </Modal>

    {isLoading ? (
    <div className="text-center">
      <Spinner color="primary" />
    </div>
    ) : (
    <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <div className="mb-0 flex-grow-1">
              <h3 className="pb-0 mb-0">States</h3>
            </div>
            <div className="flex-shrink-0">{/* right-aligned content */}</div>
          </div>

          {canAdd && 
          <Row className="mb-4">
          <Col lg={6} md={8} sm={12} className="mx-auto mx-0 px-0">
              <div className="text-sm-end text-end mb-2">
              <button className= "btn btn-success btn-rounded btn-secondary" onClick={() => { setModal(true); }}><i className="mdi mdi-plus me-1"></i>Add New State</button>
              </div>
          </Col>
          </Row>
          }
          <Row className="m-0 p-0">
            <Col className="mx-auto mx-0 px-0">
              <p className="text-muted text-center m-0 p-0">Manage the states for your project and arrange them in the desired sequence to match your workflow.</p>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={8} sm={12} className="mx-auto mx-0 px-0">
              <div className="d-flex justify-content-end my-3 w-100">
                <button className="btn btn-soft-success" onClick={() => {setUpdateModal(true)}} disabled={isSubmitting || !hasUnsavedChanges}>
                  {isSubmitting ? "Updating..." : "Update Sequence"}
                </button>
              </div>
            </Col>
          </Row>

          {statesData && statesData.length !== 0 && (
            <Row>
              <Col lg={6} md={8} sm={12} className="mx-auto mb-3 px-0">
                <div className="bg-success-subtle p-3 d-flex justify-content-between align-items-center rounded">
                  {/* State Heading */}
                  <h5 className="font-size-16 fw-bold text-dark mb-0">State</h5>
                  
                  {/* Sequence Heading */}
                  <h5 className="font-size-16 fw-bold text-dark mb-0">Sequence</h5>
                </div>
              </Col>

              {statesData.map((state, index) => (
                <div className="mb-3 mx-0 px-0" key={index}>

                  <Col lg={6} md={8} sm={12} className="mx-auto">
                    <State
                      key={state.id}
                      id={state.id}
                      text={state.title}
                      index={index}
                      length={statesData.length}
                      color={state.color}
                      canEdit={canEdit}
                      canDelete={canDelete}
                      moveState={moveState}
                      onEdit={(item) => { setCurrentState(item); setModal(true); }}
                      onDelete={(item) => { setCurrentState(item); setDeleteModal(true); }}
                    />
                  </Col>
                </div>
              ))}
            </Row>
          )}

          {statesData && statesData.length === 0 && <p className="text-danger text-center">No Sate Found. Add new state to continue.</p>}

        </CardBody>
     </Card>
  )}
    </>
)
};

export default StateList;
