import React, { useEffect, useState } from "react";
import GoBackButton from "Components/Common/GoBackButton";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, FormFeedback, Label, Row, Spinner } from "reactstrap";
import { Button, Form } from "reactstrap";
import InputElement from "Components/Custom/InputElement";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { getUsers } from "helpers/API Calls/Space Settings/Users Settings/Users/users";
import { getProjects } from "helpers/API Calls/Space Settings/Projects/projects";
import { createTeam } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";

function CreateTeam() {
  document.title = "Create Team | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const navigate = useNavigate(); 

  const formik: any = useFormik({
    initialValues: {
      teamName: "",
      project: [],
      users: []      
    },
    validationSchema: yup.object().shape({
      teamName: yup.string()
      .matches(/^[A-Za-z0-9\s]+$/, 'Team Name can only contain letters, numbers, and spaces')
      .min(3, 'Team Name must be at least 3 characters')
      .max(20, 'Team Name cannot exceed 20 characters')
      .required("Please Enter Team Name"),
      project: yup.array()
      .min(1, "Please Pick at least 1 Project")
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
      ),
      users: yup.array()
      .min(1, "Please Pick at least 1 member")
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
      )
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data = {
        team_name: values.teamName,
        projects: values.project.map(item => {return {project_id: item.value}}),
        members: values.users.map(item => {return {user_id: item.value}})
      };
      const response = await createTeam(data); 
      if(response){setSubmitting(false);}
      if (response.success) {
        formik.resetForm();
        setTimeout(() => {navigate(`/teams?businessId=${businessId}`);}, 2000);
      }
      return;
    },
  });

  const [projects, setProjects] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const userData = await getUsers(businessId);
      if (userData && userData.length > 0) {
        setUsers(userData?.map(item => ({ label: `${item.name}, ${item.email}. (${item.role})`, value: item.id })));
      }else{setUsers([]); }
      const projectData = await getProjects(businessId); 
      if (projectData && projectData.length > 0) {
        setProjects(projectData?.map(item => ({ label: `${item.project_name}, ${item.project_key}. (${item.project_type})`, value: item.id })));
      }else{setProjects([]); }
      setIsLoading(false);
    }
    getData();
  }, [businessId]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <GoBackButton to="/teams" />
        <Row>
          <Col>
          {isLoading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-2">
                  <h3 className="mb-0 flex-grow-1 ">Create Team</h3>
                  <div className="flex-shrink-0">{/* ride justified data */}</div>
                </div>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">

                  <InputElement
                    name="Team Name"
                    type="text"
                    identifier="teamName"
                    value={formik.values.teamName}
                    onChange={formik.handleChange}
                    errors={formik.errors.teamName}
                    touched={formik.touched.teamName}
                    onBlur={formik.handleBlur}
                  />

                  <Row className="mb-3">
                    <Col lg={5} sm={7} className="mx-auto">
                      <Label htmlFor="users">Members</Label>
                      <Select
                        id="multiSelect"
                        name="users"
                        isMulti
                        options={users}
                        onChange={(selectedOption) => formik.setFieldValue("users", selectedOption)}
                        onBlur={formik.handleBlur}
                        value={formik.values.users}
                      />
                      {formik.touched.users && formik.errors.users ? (
                        <FormFeedback type="invalid" className="d-block">
                          {formik.errors.users}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={5} sm={7} className="mx-auto">
                      <Label htmlFor="project">Project</Label>
                      <Select
                        id="multiSelect"
                        name="project"
                        className="border border-1 border light"
                        isMulti
                        options={projects}
                        onChange={(selectedOption) => formik.setFieldValue("project", selectedOption)}
                        onBlur={formik.handleBlur}
                        value={formik.values.project}
                      />
                      {formik.touched.project && formik.errors.project ? (
                        <FormFeedback type="invalid" className="d-block">
                          {formik.errors.project}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row>
                        <div className="d-flex flex-wrap gap-2 justify-content-end col-lg-7 col-md-10 mx-auto px-1 mt-1">
                          <Button 
                            type="submit" 
                            color="success" 
                            disabled={formik.isSubmitting} // Disable button during submission
                          >
                            {formik.isSubmitting ? 'Creating...' : 'Create Team'}
                          </Button>
                        </div>
                      </Row>

                </Form>
              </CardBody>
            </Card>
             )}
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateTeam;
