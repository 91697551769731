import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import withRouter from "Components/Common/withRouter";
import user1 from "assets/images/users/user.jpg";
import { LinkWithQuery } from "helpers/link_helper";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({ user: user.user,})
);

const ProfileMenu = (props: any) => {
  const [menu, setMenu] = useState(false);
  const { user } = useSelector(selectProperties);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const shouldPreserveId = businessId !== null; 

  return (
    <React.Fragment>

      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">

        <DropdownToggle className="btn header-item drop-menu-icon" id="page-header-user-dropdown" tag="button">
          <img className="rounded-circle header-profile-user" src={user?.profile_image || user1} alt=""/>
          <span className="d-none d-xl-inline-block ms-2 me-1">{user?.name || "user"}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block my-auto" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">

          <LinkWithQuery to="/profile"  admin={!shouldPreserveId} className="text-dark">
            <DropdownItem tag="div">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              <span>Profile</span>
            </DropdownItem>
          </LinkWithQuery>

          <LinkWithQuery to="/change-password"  admin={!shouldPreserveId} className="text-dark">
            <DropdownItem tag="div">  
                <i className="mdi mdi-key-variant font-size-16 align-middle me-1" />
                <span>Change Password</span>
            </DropdownItem>
          </LinkWithQuery>

          <div className="dropdown-divider" />

          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
          
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));