import { createSlice } from "@reduxjs/toolkit";
import { getActivity } from "./thunk";

export const initialState = {
    activityList: [], 
    loading: true,
    pagination: {current_page : 1, per_page : 10,}
}

const ActivitySlice = createSlice({
    name: "ActivitySlice", 
    initialState, 
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(getActivity.fulfilled, (state: any, action: any) => {
            state.activityList = action.payload.items;
            state.pagination = action.payload.pagination;
            state.loading = false; // Set loading to false when request is done
        });
        builder.addCase(getActivity.pending, (state: any, action: any) => {
            state.activityList = []; // reset any previous data while fetching new data
            state.loading = true; 
        });
        builder.addCase(getActivity.rejected, (state: any, action: any) => {
            state.activityList = []; // reset any previous data
            state.loading = false; 
        });
    }
    
})


export default ActivitySlice.reducer
