import { combineReducers } from "redux";

import ProfileReducer from "./auth/profile/reducer";
import ForgotPasswordReducer from "./auth/forgetpwd/reducer";

import businessesReducer from './businesses/reducer'; 
import billingReducer from './billing/reducer'; 
import rolesReducer from './roles/reducer'; 
import usersReducer from './users/reducer'; 
import teamsReducer from './teams/reducer'
import currentUserReducer from './current user/reducer';
import AccountReducer from "./auth/register/reducer";
import LoginReducer from "./auth/login/reducer";
import ProjectReducer from "./projects/reducer"
import ActivityTypeReducer from "./activity types/reducer"
import ActivityStateReducer from './activity state/reducer'
import ActivityReducer from './activity/reducer'
import FilesReducer from './files/reducer'

const rootReducer = combineReducers({

    businesses: businessesReducer,

    billing: billingReducer,

    project: ProjectReducer, 
    roles: rolesReducer,
    users: usersReducer, 
    teams: teamsReducer,

    activityType : ActivityTypeReducer,
    activityState : ActivityStateReducer,
    files: FilesReducer,

    activity: ActivityReducer,

    user: currentUserReducer, //current user 
    Account: AccountReducer, //register
    Login: LoginReducer,

    //extra
    Profile: ProfileReducer,
    ForgetPassword: ForgotPasswordReducer,
});

export default rootReducer;