import { toast } from "react-toastify";
import request from "../../../request_helper";
// import { success } from "toastr";

export const getPriorities = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activity_priorities",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const addActivityComments = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activity_comments",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData), 
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        // toast.success(response.message, { autoClose: 2000 });
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false, message: error.message}
    }
}

export const getActivities = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `activities?project_id=${id}&limit=1000`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getActivityDetail = async (Id: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `activities/${Id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const createActivity = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activities",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData), 
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const editActivity = async (reqData, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activities/" + id,
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false}; 
    }
}

export const deleteActivity = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activities/" + id,
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data.success; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return false
    }
}

