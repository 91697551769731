import React from 'react'
import { Form, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';

function PublishModal({modal, setModal, action, handleSchedule = () => {}, handleFormSubmit, dateInputRef = null }) {
  return (
    <Modal isOpen={modal} toggle={() => setModal(false)} centered={true}>
      <div className="modal-content"></div>
      <ModalBody className="px-4 py-5">
        <button type="button" onClick={() => setModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
        <div className="mb-4 text-center">
          <div className="text-primary font-size-20">
            {action === "Publish" && "Publish Post"}
            {action === "Schedule" && "Schedule Post"}
            {action === "Draft" && "Save as Draft"}
            {action === "Edit" && "Edit Activity"}
            {action === "Ready" && "Mark as Ready"}
          </div>
        </div>
        {action === "Publish" && <p className="text-center text-muted font-size-16 mb-4">Are you sure you want to publish the post?</p>}
        {action === "Draft" && <p className="text-center text-muted font-size-16 mb-4">Are you sure you want to save the post as draft?</p>}
        {action === "Edit" && <p className="text-center text-muted font-size-16 mb-4">Are you sure you want to edit the post?</p>}
        {action === "Ready" && <p className="text-center text-muted font-size-16 mb-4">Are you sure you want to mark the post as ready to publish?</p>}
        {action === "Schedule" ?
          <Form onSubmit={handleSchedule}>
            <FormGroup className="select2-container ">
              <Label className="col-form-label">Pick time</Label>
              <Input type="datetime-local" id="publish-time" name="publish-time" innerRef={dateInputRef}/>
            </FormGroup>
            <div className="hstack gap-2 mb-0 justify-content-end">
              <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
              <button type="submit" className="btn btn-success" onClick={() => {handleFormSubmit(); setModal(false);}}>Schedule</button>
            </div>
          </Form>
          : 
          <div className="hstack gap-2 mb-0 justify-content-center">
            <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
            <button type="submit" className="btn btn-danger" onClick={() => {handleFormSubmit(); setModal(false);}}>Yes</button>
          </div>
        }
      </ModalBody>
    </Modal>
  )
}

export default PublishModal