import React, { useState } from "react";
import { Row, CardTitle, CardText, CardBody } from "reactstrap";
import CollapseItem from "./UI/CollapseItem";

function Posts() {
  const [collapseStates, setCollapseStates] = useState(true);
  return (
    <Row>
        <CollapseItem title="Posts" col={collapseStates} toggle={() => setCollapseStates((prevState) => !prevState)}>
            <CardBody className="mx-3 border-bottom">
            <CardTitle tag="h4" className="mt-0">
                Special title treatment
            </CardTitle>
            <CardText>
                With supporting text below as a natural lead-in to additional
                content.
            </CardText>
            </CardBody>
            <hr className="my-1" />
            <CardBody className="mx-3 border-bottom">
            <CardTitle tag="h4" className="mt-0">
                Special title treatment
            </CardTitle>
            <CardText>
                With supporting text below as a natural lead-in to additional
                content.
            </CardText>
            </CardBody>
      </CollapseItem>
    </Row>
  );
}

export default Posts;
