import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import placeholderImage from "assets/images/img.png"
import { editProject, getProjectDetail, getProjectType } from "helpers/API Calls/Space Settings/Projects/projects";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import Dropzone from "Components/Custom/DropZone";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-based
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
};

type ProjectTypeOption = {
  label: string;
  value: string | number;
};


function GeneralSettings() {
  document.title = "General Settings | Content Pro";
  const navigate = useNavigate(); 

  const selectProperties = createSelector(
    (state: any) => state.user,
    (user) => ({user: user.user,})
  );
  const { user } = useSelector(selectProperties);


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  let projectId = searchParams.get('projectId');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectData, setProjectData] = useState<any>({});
  
  //  img upload
  const [imageUrl, setImageUrl] = useState<string>(""); // state for image URL
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  // validation
  const validation: any = useFormik({
    initialValues: {
      title: "",
      type: null,
      startDate: '',
      dueDate: '', 
    },
    validationSchema: Yup.object({
      title: Yup.string()
      .matches(/^[A-Za-z0-9\s]+$/, 'Project Name can only contain letters, numbers, and spaces')
      .min(3, 'Project Name must be at least 3 characters')
      .max(50, 'Project Name cannot exceed 50 characters')
      .required("Please Enter Project Name"),
      type: Yup
      .object()
      .shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
      .required("Please Select type"),
      startDate: Yup.string(),
      dueDate: Yup.string(),
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data: any = {
        project_name: values.title,
        business_id: projectData?.business_id,
        project_key: projectData?.project_key,
        project_type_id: values.type.value,
        started_by: projectData?.started_by,
      };
    
      // Conditionally add startDate and dueDate if they are provided
      if (values.startDate) {
        data.started_at = `${values.startDate} 00:00:00`;
      }
      if (values.dueDate) {
        data.due_date = `${values.dueDate} 00:00:00`;
      }

      const response = await editProject({ data: data, thumbnail: selectedImage || null }, projectData.id);

      if (response) {
        setSubmitting(false);
      }
  
      if (response?.success && user.user_type !== 'Member') {
        setTimeout(() => { navigate(`/projects?businessId=${businessId}`); }, 2000);
      }
    
      return;
    }    
  });

  // drop down options 
  const [projectType, setProjectType] = useState<ProjectTypeOption[]>([]);
  const handleSelectChange = (selectedOption) => {
    validation.setFieldValue("type", selectedOption);
  };

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      try {
        const data = await getProjectDetail(projectId); 
        const response = await getProjectType(); 
  
        if (!data || !response || !Array.isArray(response)) {
          setIsLoading(false);
          return;
        }
  
        const projectTypeOptions = response.map((item) => ({
          label: item?.project_type || "Unknown Type",
          value: item?.id || null,
        }));
  
        setProjectType(projectTypeOptions);
        setProjectData(data);
        setImageUrl(data?.thumbnail || "");
  
        const selectedType = projectTypeOptions.find(
          (item) => item.value === data?.project_type_id
        );
  
        validation.setValues({
          title: data?.project_name || "",
          type: selectedType || null,
          startDate: data?.started_at ? formatDate(data?.started_at) : "", // Set only if not null
          dueDate: data?.due_date ? formatDate(data?.due_date) : "", // Set only if not null
        });        
  
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setIsLoading(false);
      }
    };
    getData();
  }, [projectId]);
  

  
  return (
    <div className="page-content">
      <Container fluid>
      {isLoading ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
        ) : (
        <Card>
          <CardBody>
            <>
            <div className="d-flex align-items-center mb-2">
              <h3 className="mb-0 flex-grow-1 ">Project General Settings</h3>
              <div className="flex-shrink-0">{/* ride justified data */}</div>
            </div>
            <Row>
             {(!projectData || !projectType.length) ? <p className="text-center text-danger">Project Not Found.</p>
             :
             
              <Col lg={5} sm={7} className="mx-auto">
                <Form className="mt-4" onSubmit={(e: any) => { e.preventDefault(); validation.handleSubmit(); return false;}}>
                  <Row>
                    <Col className="mx-auto">
                      <div className="mb-3">
                        <Label htmlFor="name-input">Project Logo</Label>
                        <Dropzone
                          accept = {{"image/*": [".png", ".jpeg", ".jpg"]}}
                          onDrop = {(acceptedFiles) => {setSelectedImage(acceptedFiles[0]);}}
                          renderContent = {() => (
                            <div className="text-center cursor-pointer">
                              <div className="position-relative d-inline-block" >
                                <div className="avatar-xl rounded">
                                  <div className="avatar-title bg-light ">
                                    {selectedImage ? (
                                      <img src={URL.createObjectURL(selectedImage)} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                    ) : (
                                      <img src={imageUrl || placeholderImage} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mx-auto">
                      <div className="mb-3">
                        <Label htmlFor="name-input">Project Name</Label>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          placeholder="Enter Title..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title}
                          // disabled
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.title}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="mx-auto">
                      <Label htmlFor="type">Type</Label>
                      <Select
                        id="type"
                        name="type"
                        placeholder= "Select Project Type"
                        options={projectType}
                        value={validation.values.type}
                        onChange={handleSelectChange}
                        onBlur={validation.handleBlur}
                        className={
                          validation.touched.type && validation.errors.type
                            ? "is-invalid"
                            : ""
                        }
                        
                      />
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="mx-auto">
                      <Label>Start Date</Label>
                      <Input 
                        id="startDate"
                        name="startDate"
                        type="date" 
                        placeholder="Enter Due Date"
                        className="form-control d-block"
                        onChange={(event: any) => validation.setFieldValue("startDate", event.target.value)}
                        onBlur={validation.handleBlur}
                        value={validation.values.startDate || ''}
                        invalid={validation.touched.startDate && validation.errors.startDate ? true : false}
                      />
                      {validation.errors.startDate && validation.touched.startDate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.startDate}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="mx-auto">
                      <Label>Due Date</Label>
                      <Input 
                        id="dueDate"
                        name="dueDate"
                        type="date" 
                        placeholder="Enter Due Date"
                        className="form-control d-block"
                        onChange={(event: any) => validation.setFieldValue("dueDate", event.target.value)}
                        onBlur={validation.handleBlur}
                        value={validation.values.dueDate || ''}
                        invalid={validation.touched.dueDate && validation.errors.dueDate ? true : false}
                      />
                      {validation.errors.dueDate && validation.touched.dueDate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.dueDate}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto px-1 mt-4">
                      <Button type="submit" color="success" disabled={validation.isSubmitting}>{validation.isSubmitting ? 'Saving...' : 'Save Changes'}</Button>
                    </div>
                  </Row>
                </Form>
              </Col>
             }
            </Row>
            </>
          </CardBody>
        </Card>
        )}
        <ToastContainer />
      </Container>
    </div>
  );
}

export default GeneralSettings;
