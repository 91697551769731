import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, ModalBody, Row } from "reactstrap";
import CustomIcon from "Components/Custom/CustomIcon";
import { createSelector } from "reselect";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import { getFiles } from "slices/thunk";
import convertDateFormat from "Components/Custom/DateFormat";
import { ToastContainer } from "react-toastify";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import { deleteFile, uploadFile } from "helpers/API Calls/Project/Activity/files";
import placeholderImage from "assets/images/img.png";
import Dropzone from "Components/Custom/DropZone";

const head = [
  { id: 1, name: "#", isSortable: true, key: "id" },
  { id: 2, name: "File", isSortable: true, key: "file_name" },
  { id: 8, name: "Type", isSortable: true, key: "file_type" },
  { id: 5, name: "Size (kb)", isSortable: true, key: "file_size" },
  { id: 3, name: "Uploaded By", isSortable: true, key: "uploaded_by_name" },
  { id: 4, name: "Uploaded", isSortable: true, key: "created_at" },
  { id: 6, name: "Actions" },
];

const selectProperties = createSelector(
  (state: any) => state.files,
  (state: any) => state.user,
  (files, user) => ({
    files: files.filesList,
    loading: files.loading,
    pagination: files.pagination,
    permissions: user.permissions,
    user: user.user
  })
);

function Files() {
  document.title = "Project Files | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let projectId = searchParams.get("projectId");

  const { files, loading, pagination, permissions, user  } = useSelector(selectProperties);
  const [filesData, setFilesData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedFile, setSelectedFile] = useState<any>({});
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<{ file: File | null, type: "image" | "video" | null }>({ file: null, type: null });


  useEffect(() => {
    setIsLoading(loading);
    setFilesData(files);
  }, [files, loading]);

  const handleDelete = async () => {
    if (selectedFile) {
      const response = await deleteFile(selectedFile.id);
      setDeleteModal(false);
      setIsDeleted(response);
      setSelectedFile({});
    }
  };

  useEffect(() => {
    if (isDeleted) setIsDeleted(false);
  }, [isDeleted]);

  const handleMediaUpload = async () => {
    if (uploadedImage.file) {
      setIsSubmitting(true);
      const data = {
        file_type: uploadedImage.type, 
        file: uploadedImage.file,
        project_id: projectId
      };
      const response = await uploadFile(data);
      if(response){
        setIsDeleted(true);
      }
      setIsSubmitting(false);
      closeModal();
    }
  };
  
  const closeModal = () => {
    setModal(false);
    setUploadedImage({ file: null, type: null });
  };
  
  const canUpload = user?.user_type === 'Member' ? permissions?.includes('upload_files') : true;
  const canDownload = user?.user_type === 'Member' ? permissions?.includes('download_files') : true;

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => { setSelectedFile({});setDeleteModal(false);}}
        message={`${selectedFile?.file_type} file "${selectedFile?.file_name}"`}
      />

      <Modal isOpen={modal} toggle={closeModal} centered backdrop="static">
        <div className="modal-content">
          <ModalBody className="px-4 pt-5 text-center">
            <button type="button" onClick={closeModal} className="btn-close position-absolute end-0 top-0 m-3" disabled={isSubmitting}></button>
            <div className="mb-4 text-center font-size-20 text-primary">Add Media</div>
            <Dropzone
                accept = {{"image/*": [".png", ".jpeg", ".jpg", ".gif"],"video/*": [".mp4", ".mov", ".avi"],}}
                onDrop = {(acceptedFiles) => {
                  if (acceptedFiles.length === 0) return;
                  const file = acceptedFiles[0];
                  const fileType = file.type.startsWith("image/") ? "image" : "video";
                  setUploadedImage({ file, type: fileType });
                }}
                renderContent = {() => (
                  <div className="avatar-xl rounded mx-auto">
                  <div className="avatar-title bg-light">
                    {uploadedImage.file ? (
                      uploadedImage.type === "image" ? (
                        <img src={URL.createObjectURL(uploadedImage.file)} alt="Selected file" className="avatar-xl rounded h-100" />
                      ) : (
                        <video controls className="avatar-xl rounded h-100">
                          <source src={URL.createObjectURL(uploadedImage.file)} type="video/mp4" />
                        </video>
                      )
                    ) : (
                      <img src={placeholderImage} alt="Placeholder" className="avatar-xl rounded h-100" />
                    )}
                  </div>
                </div>
                )}
              />
            <div className="hstack gap-2 mb-0 justify-content-end">
              <Button type="button" color="secondary" onClick={closeModal} disabled={isSubmitting}>
                Close
              </Button>
              <Button type="submit" color="success" disabled={isSubmitting} onClick={handleMediaUpload}>
                {isSubmitting ? "Submitting..." : "Save"}
              </Button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <PaginatedTable
                  tableHead={head}
                  onGetData={getFiles}
                  pagination={pagination}
                  isLoading={isLoading}
                  isDeleted={isDeleted}
                  isAddButton={false}
                  isAddModal={canUpload}
                  onClick={() => setModal(true)}
                  buttonText="Add New File"
                  query={`project_id=${projectId}`}
                  title="Files"
                >
                  {!isLoading && filesData.length !== 0 && filesData.map((item: any) => (
                    <tr key={item.id}>
                      <th scope="row">{item?.id}</th>
                      <td className="d-flex"><h5 className="font-size-14 mb-1">{item?.file_name}</h5></td>
                      <td>{item?.file_type}</td>
                      <td>{item?.file_size}</td>
                      <td>{item?.uploaded_by_name}</td>
                      <td>{convertDateFormat(item?.created_at)}</td>
                      <td>
                        {canDownload && <Button className="btn btn-sm btn-soft-primary me-2" 
                          onClick={() => {
                            // try {
                            //   const link = document.createElement("a");
                            //   link.href = item?.file;
                            //   link.download = item?.file_name;
                            //   document.body.appendChild(link);
                            //   link.click();
                            //   document.body.removeChild(link);
                            // } catch (error) {
                            //   console.error("Error downloading the file:", error);
                            // }
                            window.open(item?.file, '_blank'); 
                          }}
                        >
                          <CustomIcon className="mdi" iconName="mdi-download" text="View" id={`download-${item.id}`}/>
                        </Button>}
                        <Button className="btn btn-sm btn-soft-danger" onClick={() => { setSelectedFile(item); setDeleteModal(true); }}>
                          <CustomIcon className="mdi" iconName="mdi-trash-can" text="Delete" id={`delete-${item.id}`} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                  {!isLoading && filesData.length === 0 && (
                    <tr className="text-center">
                      <td colSpan={head.length} className="text-danger">No Data Found</td>
                    </tr>
                  )}
                </PaginatedTable>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ToastContainer />
    </>
  );
}

export default Files;
