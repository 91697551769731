import React, { useEffect, useState } from "react";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { Col, Row, Container, Spinner, Card, CardBody } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import { getActivityDetail } from "helpers/API Calls/Project/Activity/Activity";
import Header from "./UI/activity-header";
import SimpleBar from "simplebar-react";
import History from "./Activity History/History";


function Version() {
  document.title = "Activity Logs | Content Pro";
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const returnURL = searchParams.get("return");


  const [activityData, setActivityData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      // Fetch activity data
      const data = await getActivityDetail(id);
      setActivityData(data || {});  // Set data or empty object if data is null/undefined
      setIsLoading(false);
    };
    getData();
  }, [id]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Activity" breadcrumbItem="Activity Log" queries={`return=${returnURL}`}  isGoBackButton = {true} project={true} to={`/posts-details/${id}`}/>
        <Row>
          <Col>
            {isLoading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : Object.keys(activityData).length === 0 ? (
              <div className="text-center">
                <p className="text-danger">Data not found.</p>
              </div>
            ) : (
              <Card>
                <CardBody>
                  <Header 
                    state={activityData?.activity_state?.state_name || "N/A"}
                    stateColor={activityData?.activity_state?.color || "#ccc"}
                    type={activityData?.activity_types?.activity_type || "N/A"}
                    typeColor={activityData?.activity_types?.color || "#ccc"}
                    date={activityData?.created_at || "N/A"}
                    due={activityData?.due_date}
                    creator={activityData?.creator_name || "N/A"}
                    team={activityData?.team_name || ""}
                    title={activityData?.title || "Untitled"}
                  />

                  <div className="bg-light rounded card-body mt-4">
                    {activityData?.activity_logs && activityData?.activity_logs.length > 0 ? (
                      <SimpleBar style={{ maxHeight: "376px" }}>
                        <ul className="verti-timeline list-unstyled">
                          {activityData?.activity_logs.map(item => 
                            <History name={item?.user?.name} timestamp={item.created_at} action={item.content} key={item?.id}/>
                          )}
                        </ul>
                        {/* <div className="text-center mt-4"><Link to="#" className="btn btn-primary waves-effect waves-light btn-sm">View More <i className="mdi mdi-arrow-right ms-1"></i></Link></div> */}
                      </SimpleBar>
                    ) : (
                      <div className="text-center mt-4">
                        <p className="text-danger">No version logs available.</p>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Version;
