import React, { useEffect, useState } from "react"
import { Container, Card, CardBody, Table,} from "reactstrap";
import { getPlans } from "helpers/API Calls/Super Admin/Billing";
import { Spinner } from "reactstrap";
import { ToastContainer } from "react-toastify";
import convertDateFormat from "Components/Custom/DateFormat";


function Plans() {

  document.title = "Pricing | Content Pro";

    // local state mng for getting data
    const [planData, setPlanData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const data = await getPlans(); 
      setPlanData(data);
      setIsLoading(false);
    }
    getData(); 
    // console.log("Plan data", planData)
  }, []);
  


  return (
    <React.Fragment>
      <ToastContainer/>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Billing" breadcrumbItem="Plans" /> */}
          <Card>
          {isLoading ? (
            <Spinner
              color="primary"
              className="position-absolute top-50 start-50"
            />
            ) : (
            
            <CardBody className="p-3">
              <div className="d-flex align-items-center mb-4">
                  {/* <CardTitle tag="h5" className="mb-0 flex-grow-1">Pricing Plans</CardTitle> */}
                  <h3 className="mb-0 flex-grow-1 ">Pricing Plans</h3>
              </div>
              <div className="table-responsive">
                <Table hover className="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline">
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>Plan</th>
                      <th>Stripe Id</th> 
                      <th>Product Id</th> 
                      <th>Pricing</th> {/* $45USD/month* */}
                      <th>Status</th> 
                      <th>Created</th>
                      <th>Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {planData && planData?.map((item : any, idx : any) => 
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.product_name}</td>
                        <td>{item.price_id}</td>
                        <td>{item.product_id}</td>
                        <td>{`$${item.price}${item.currency.toUpperCase()}/${item.interval}`}</td>
                        <td>{item.status ? <span className="font-size-11 badge badge-soft-success">Active</span> : <span className="font-size-11 badge badge-soft-danger">In Active</span>}</td>
                        <td>{convertDateFormat(item.created_at)}</td>
                        <td>{convertDateFormat(item.updated_at)}</td>
                      </tr>
                    )}
                    {!planData && <tr className="text-center"><td colSpan={7} className="text-danger">No Data Found</td></tr>}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            
          )}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Plans