import React, { useEffect, useState } from "react";
import { Card, Container, } from "reactstrap";
import CustomIcon from "Components/Custom/CustomIcon";
import { LinkWithQuery } from "helpers/link_helper";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import { getProject } from "slices/thunk";
import convertDateFormat from "Components/Custom/DateFormat";
import { useLocation } from "react-router-dom";
import { deleteProject } from "helpers/API Calls/Space Settings/Projects/projects";

// redux code
const selectProperties = createSelector(
  (state: any) => state.project,
  (project) => ({
    project: project.projectList,
    loading: project.loading,
    pagination: project.pagination
  })
);
const head = [
  {id: 1, name: "#", isSortable: true, key: "id"},
  {id: 2, name: "Project", isSortable: true, key: "project_name"},
  {id: 3, name: "Project Type", isSortable: true, key: "project_type"},
  {id: 4, name: "Started", isSortable: true, key: "started_at"},
  {id: 5, name: "Started By", isSortable: true, key: "started_by_user"},
  {id: 6, name: "Due", isSortable: true, key: "due_date"},
  {id: 7, name: "Created", isSortable: true, key: "created_at"},
  {id: 87, name: "Updated", isSortable: true, key: "updated_at"},
  {id: 8, name: "Actions"}
]

function Projects() {
  document.title = "Projects | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

    const { project, loading, pagination } = useSelector(selectProperties);
  
    // local state mng for getting data
    const [projectData, setProjectData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
  
    useEffect(() => {
      setIsLoading(loading);
      setProjectData(project);
    }, [project, loading]);

    
  // for deletion 
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] =useState<any>();
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const handleDelete = async () => {
    if (deleteItem) {
      const response = await deleteProject(deleteItem.id);
      setDeleteItem(null);
      setDeleteModal(false);
      setIsDeleted(response); 
    }
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  return (
    <>
      <DeleteModal
      show={deleteModal}
      onDeleteClick={handleDelete}
      onCloseClick={() => {setDeleteModal(false); setDeleteItem({});}}
      message={`Project ${deleteItem?.project_name}(${deleteItem?.project_key})`}
      />

     <div className="page-content">
      <Container fluid>
        <Card>
          <PaginatedTable
            tableHead={head}
            onGetData = {getProject}
            pagination = {pagination}
            isLoading = {isLoading}
            buttonText = "Add New Project" 
            isAddButton = {true} 
            query = {`business_id=${businessId}`}
            to= {`create?businessId=${businessId}`}
            isDeleted = {isDeleted}
            title = 'Projects List'
          >
            {projectData.length !== 0 && projectData.map((item : any, index : any) => (
              <tr key={item.id}>

                <th scope="row">{item.id}</th>
                <td>
                  <LinkWithQuery to={`${item.id}`} className="d-flex">
                  <div className="flex-shrink-0 me-2">
                    {item?.thumbnail ? 
                      <img src={item?.thumbnail} alt="" className="avatar-xs rounded-circle"/>
                      :
                      <div className="avatar-xs">
                        <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                          {item.project_name?.charAt(0)}
                        </div>
                      </div>
                    }
                    </div>
                    <div>
                    <h5 className='font-size-14 mb-1 text-dark'>
                      {item.project_name}
                    </h5>
                    <p className="text-muted mb-0">{item.project_key}</p>
                  </div>
                  </LinkWithQuery>
                </td>
                <td>{item.project_type}</td>
                <td>{convertDateFormat(item.started_at) || 'Not Specified'}</td>
                <td>{item.started_by_user}</td>
                <td>{convertDateFormat(item.due_date) || 'Not Specified'}</td>
                <td>{convertDateFormat(item.created_at)}</td>
                <td>{convertDateFormat(item.updated_at)}</td>
                <td className="d-flex">
                  <LinkWithQuery to={`${item.id}`} className="btn btn-sm btn-soft-primary">
                    <CustomIcon className="mdi " iconName= "mdi-eye-outline" id={`view-${item.id}`} text="View"/>
                  </LinkWithQuery>
                  <LinkWithQuery to="/general" queries={`projectId=${item.id}`} className="btn btn-sm btn-soft-info mx-2" title="Edit Project">
                    <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                  </LinkWithQuery>
                  <button className="btn btn-sm btn-soft-danger me-2" onClick={() => {setDeleteModal(true); setDeleteItem(item);}} title="Delete Project">
                    <CustomIcon className="mdi " iconName= "mdi-delete-outline" id={`remove-${item.id}`} text="Remove"/>
                  </button>
                </td>
              </tr>
            ))}
            {projectData.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
          </PaginatedTable>
        </Card>
        <ToastContainer />
      </Container>
    </div>
    </>
  );
}

export default Projects;
