import React, { useEffect, useState } from "react";
import { Row, Col, CardTitle, CardText, CardBody, Spinner, Input } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import convertDateFormat from "Components/Custom/DateFormat";
import CollapseItem from "pages/Dashboard/UI/CollapseItem";
import { LinkWithQuery } from "helpers/link_helper";
import { getActivities } from "helpers/API Calls/Project/Activity/Activity";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({
    permissions: user.permissions,
    user: user.user
  })
);

function Activities(props : any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const projectId = searchParams.get("projectId");

  const { permissions, user } = useSelector(selectProperties);

  const [collapseStates, setCollapseStates] = useState(true);
  const [activities, setActivities] = useState<any>(null);
  const [filteredActivities, setFilteredActivities] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const activityData = await getActivities(projectId);
      if (activityData && activityData.length > 0) {
        setActivities(activityData);
        setFilteredActivities(activityData);
      } else {
        setActivities([]);
        setFilteredActivities([]);
      }
      setIsLoading(false);
    };
    getData();
  }, [projectId]);

  useEffect(() => {
    if (activities) {
      const filtered = activities.filter(
        (activity: any) =>
          activity.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          activity.activity_type.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredActivities(filtered);
    }
  }, [searchTerm, activities]);

  const canAdd = user?.user_type === 'Member' ? permissions?.includes('create_post') : true;

  return (
    <Row>
      <CollapseItem title="Activities" col={collapseStates} toggle={() => setCollapseStates((prevState) => !prevState)}>
        {!isLoading && activities && activities.length > 0  && <Row className="mt-4 mx-3">
          <Col sm="12" md="8">
            <div className="app-search p-0">
              <div className="form-group m-0">
                <div className="input-group">
                  <Input
                    type="text"
                    placeholder="Search activities by name or type..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control bg-white border border-opacity-25 border-success"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>}
        {isLoading ? (
          <div className="text-center my-5">
            <Spinner color="primary" />
          </div>
        ) : filteredActivities && filteredActivities.length > 0 ? (
          <>
          {filteredActivities.slice(0, 2).map((item) => (
            <LinkWithQuery to ={`/posts-details/${item.id}`} key={item.id} project={true} queries='return=posts-list'>
              <div className="d-flex mx-3 border-bottom">
                <div className="flex-shrink-0 my-auto ms-3">
                    <div className="avatar-xs">
                        <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                        {item.title?.charAt(0)}
                        </div>
                    </div>
                  </div>
                <div className="flex-grow-1">
                  <CardBody>
                    <CardTitle tag="h4" className="d-flex justify-content-between align-items-center">
                      {item.title}
                      <LinkWithQuery to ={`/posts-details/${item.id}/publish`} project={true} queries='return=posts-list' className="btn btn-soft-success btn-sm me-1">
                        Publish
                      </LinkWithQuery>
                    </CardTitle>
                    <CardText className="text-muted">{item.activity_type} - Started on {convertDateFormat(item.created_at)}</CardText>
                  </CardBody>
                </div>
              </div>
            </LinkWithQuery>
          ))}
          {filteredActivities.length > 2 && 
            <div className="text-center">
              <LinkWithQuery className="btn text-success" to ='/posts-list' project={true}>
                Show All
              </LinkWithQuery>
            </div>
          }
          </>
        ) : (
          <>
          <CardBody className="mx-3">
            <CardTitle tag="h4">Sorry! No Data found.</CardTitle>
            {canAdd && <CardText className="text-muted d-flex justify-content-between col-xl-6 col-md-8">
              <span className="my-auto">Start a new Activity.</span>
              <button className="btn btn-soft-success " onClick={() => {navigate(`/posts-create?return=posts-list&projectId=${projectId}&businessId=${businessId}`)}}>Create New Activity</button>
            </CardText>}
          </CardBody>
          </>
        )}
      </CollapseItem>
    </Row>
  );
}

export default Activities;
