import React, { useEffect, useState } from "react";
import { Spinner, Row, Col, Card, CardBody, Label, Button, Form, Input, Modal, ModalBody, FormFeedback } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { getSettings, updateSettings } from "helpers/API Calls/Super Admin/settings";

function General() {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [publicReg, setPublicReg] = useState<Number>();
  const [modal, setModal] = useState(false);

  const billingFormik: any = useFormik({
    initialValues: {
      key: "",
      id: "",
      trial: "",
    },
    validationSchema: Yup.object({
      key: Yup.string().required("This field is required"),
      id: Yup.string().required("This field is required"),
      trial: Yup.number().required("This field is required"),
    }),

    onSubmit: (values: any) => {
      console.log("value", values);
    },
  });

  // Formik for File Settings
  const fileSettingsFormik: any = useFormik({
    initialValues: {
      numImages: "",
      numVideos: "",
      imageSize: "",
      videoSize: "",
    },
    validationSchema: Yup.object({
      numImages: Yup.number().required("This field is required").min(1, "Must be at least 1"),
      numVideos: Yup.number().required("This field is required").min(1, "Must be at least 1"),
      imageSize: Yup.number().required("This field is required").min(1, "Must be at least 1KB"),
      videoSize: Yup.number().required("This field is required").min(1, "Must be at least 1KB"),
    }),

    onSubmit: (values: any) => {
      console.log("File Settings Submitted", values);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const data = await getSettings(); 
      billingFormik.setValues({
        key: data?.find(setting => setting.key === "STRIPE_KEY").value,
        id: data?.find(setting => setting.key === "PRICE_ID").value,
        trial: data?.find(setting => setting.key === "FREE_TRIAL_DAYS").value,
      });
      fileSettingsFormik.setValues({
        numImages: data?.find(setting => setting.key === "IMAGES_LIMIT").value,
        numVideos: data?.find(setting => setting.key === "VIDEOS_LIMIT").value,
        imageSize: data?.find(setting => setting.key === "IMAGE_SIZE_LIMIT").value,
        videoSize: data?.find(setting => setting.key === "VIDEO_SIZE_LIMIT").value,
      });
      const reg = data?.find(setting => setting.key === "ENABLE_PUBLIC_SIGNUP")?.value || 0;
      setPublicReg(Number(reg));
      setIsLoading(false);
    }
    getData(); 
  }, []);

  const handleConfirm = async() => {
    const value = publicReg === 1 ? 0 : 1; 
    const response = await updateSettings({key: "ENABLE_PUBLIC_SIGNUP", value: value})
    setModal(false); 
    setPublicReg(response.value);
  };


  return (
    <>
    
    <Modal isOpen={modal} toggle={() => setModal(false)} centered={true}>
      <ModalBody className="px-4 py-5 text-center">
      <button type="button" onClick={() => setModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
      <div className="mb-4">
        <div className="text-primary font-size-20">Confirm Change</div>
      </div>
      <p className="text-muted font-size-16 mb-4">Are you sure you want to {publicReg ? "disable" : "enable"} public registration?</p>
      <div className="hstack gap-2 justify-content-center mb-0">
        <Button color="secondary" onClick={() => setModal(false)}>Cancel</Button>
        <Button color="danger" onClick={handleConfirm}>Confirm</Button>
      </div>
      </ModalBody>
    </Modal>
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col>
            {isLoading ? (
            <Spinner
              color="primary"
              className="position-absolute top-50 start-50"
            />
            ) : (
              <>
               <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Stripe Settings</CardTitle> */}
                  <h3 className="mb-0 flex-grow-1 mb-4">Stripe Settings</h3>
                  <Form onSubmit={billingFormik.handleSubmit}>
                    <Row className="mb-4">
                      <Label htmlFor="stripe-key" className="col-sm-3 col-form-label">Stripe Key</Label>
                      <Col sm={9}>
                        <Input
                          name="key"
                          type="text"
                          className="form-control"
                          id="stripe-key"
                          placeholder="Enter Stripe Key"
                          disabled
                          value={billingFormik.values.key}
                          onChange={billingFormik.handleChange}
                          onBlur={billingFormik.handleBlur}
                          invalid={billingFormik.touched.key && billingFormik.errors.key ? true : false}
                        />
                        {
                          billingFormik.errors.key && billingFormik.touched.key ? (
                            <FormFeedback type="invalid">{billingFormik.errors.key}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label htmlFor="horizontal-id-Input" className="col-sm-3 col-form-label">Default Plan</Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          name="id"
                          className="form-control"
                          id="price-id"
                          placeholder="Enter Price ID"
                          disabled
                          value={billingFormik.values.id}
                          onChange={billingFormik.handleChange}
                          onBlur={billingFormik.handleBlur}
                          invalid={billingFormik.touched.id && billingFormik.errors.id ? true : false}
                        />
                        {
                          billingFormik.errors.id && billingFormik.touched.id ? (
                            <FormFeedback type="invalid">{billingFormik.errors.id}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label  htmlFor="trial-period" className="col-sm-3 col-form-label">Free Trial Days</Label>
                      <Col sm={9}>
                        <Input
                          type="number"
                          name="trial"
                          className="form-control"
                          id="trial-period"
                          autoComplete="off"
                          placeholder="Enter Free Trial Period in days"
                          disabled
                          value={billingFormik.values.trial}
                          onChange={billingFormik.handleChange}
                          onBlur={billingFormik.handleBlur}
                          invalid={billingFormik.touched.trial && billingFormik.errors.trial ? true : false}
                        />
                        {
                          billingFormik.errors.trial && billingFormik.touched.trial ? (
                            <FormFeedback type="invalid">{billingFormik.errors.trial}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="text-end">
                          <Button type="submit" color="success" className="w-md">Save Changes</Button></div>
                      </Col>
                    </Row>
                  </Form>
                  <hr/>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Registration Settings</CardTitle> */}
                  <h3 className="mb-0 flex-grow-1 mb-4">Registration Settings</h3>
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row className="mb-4">
                      <Label className="col-sm-3 col-form-label">Permission</Label>
                      <Col sm={9}>
                        <div className="form-check form-switch form-switch-md">
                          <Input
                            type="switch"
                            id="public-reg-switch"
                            className='form-check-input'
                            checked={publicReg === 1}
                            onChange={() => setModal(true)}
                          />
                          <Label className="form-check-label" htmlFor="public-reg-switch">
                            Public Registration {publicReg === 1 ? "On" : "Off"}
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>

              {/* File Settings Card */}
              <Card>
                <CardBody>
                  <h3 className="mb-0 flex-grow-1 mb-4">Project File Settings</h3>
                  <Form onSubmit={fileSettingsFormik.handleSubmit}>
                    {/* Number of Images */}
                    <Row className="mb-4">
                      <Label htmlFor="num-images" className="col-sm-3 col-form-label">Number of Images</Label>
                      <Col sm={9}>
                        <Input
                          type="number"
                          name="numImages"
                          className="form-control"
                          id="num-images"
                          placeholder="Enter number of images"
                          disabled
                          value={fileSettingsFormik.values.numImages}
                          onChange={fileSettingsFormik.handleChange}
                          onBlur={fileSettingsFormik.handleBlur}
                          invalid={fileSettingsFormik.touched.numImages && fileSettingsFormik.errors.numImages ? true : false}
                        />
                        {
                          fileSettingsFormik.errors.numImages && fileSettingsFormik.touched.numImages ? (
                            <FormFeedback type="invalid">{fileSettingsFormik.errors.numImages}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>
                    
                    {/* Number of Videos */}
                    <Row className="mb-4">
                      <Label htmlFor="num-videos" className="col-sm-3 col-form-label">Number of Videos</Label>
                      <Col sm={9}>
                        <Input
                          type="number"
                          name="numVideos"
                          className="form-control"
                          id="num-videos"
                          placeholder="Enter number of videos"
                          disabled
                          value={fileSettingsFormik.values.numVideos}
                          onChange={fileSettingsFormik.handleChange}
                          onBlur={fileSettingsFormik.handleBlur}
                          invalid={fileSettingsFormik.touched.numVideos && fileSettingsFormik.errors.numVideos ? true : false}
                        />
                        {
                          fileSettingsFormik.errors.numVideos && fileSettingsFormik.touched.numVideos ? (
                            <FormFeedback type="invalid">{fileSettingsFormik.errors.numVideos}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>

                    {/* Image Size (KB) */}
                    <Row className="mb-4">
                      <Label htmlFor="image-size" className="col-sm-3 col-form-label">Image Size (KB)</Label>
                      <Col sm={9}>
                        <Input
                          type="number"
                          name="imageSize"
                          className="form-control"
                          id="image-size"
                          placeholder="Enter max image size in KB"
                          disabled
                          value={fileSettingsFormik.values.imageSize}
                          onChange={fileSettingsFormik.handleChange}
                          onBlur={fileSettingsFormik.handleBlur}
                          invalid={fileSettingsFormik.touched.imageSize && fileSettingsFormik.errors.imageSize ? true : false}
                        />
                        {
                          fileSettingsFormik.errors.imageSize && fileSettingsFormik.touched.imageSize ? (
                            <FormFeedback type="invalid">{fileSettingsFormik.errors.imageSize}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>

                    {/* Video Size (KB) */}
                    <Row className="mb-4">
                      <Label htmlFor="video-size" className="col-sm-3 col-form-label">Video Size (KB)</Label>
                      <Col sm={9}>
                        <Input
                          type="number"
                          name="videoSize"
                          className="form-control"
                          id="video-size"
                          placeholder="Enter max video size in KB"
                          disabled
                          value={fileSettingsFormik.values.videoSize}
                          onChange={fileSettingsFormik.handleChange}
                          onBlur={fileSettingsFormik.handleBlur}
                          invalid={fileSettingsFormik.touched.videoSize && fileSettingsFormik.errors.videoSize ? true : false}
                        />
                        {
                          fileSettingsFormik.errors.videoSize && fileSettingsFormik.touched.videoSize ? (
                            <FormFeedback type="invalid">{fileSettingsFormik.errors.videoSize}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>

                    {/* Submit Button */}
                    <Row>
                      <Col>
                        <div className="text-end">
                          <Button type="submit" color="success" className="w-md">Save Changes</Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>

              </>
            )}
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
    </>
  );
}

export default General;
