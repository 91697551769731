import React, { useState } from "react";
import { Button, CardBody, Modal, ModalBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, ModalHeader, UncontrolledTooltip, Form, FormGroup, Label, } from "reactstrap";
import { LinkWithQuery } from "../../../../helpers/link_helper";
import DOMPurify from 'dompurify';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CustomIcon from "../../../../Components/Custom/CustomIcon";
import convertDateFormat from "Components/Custom/DateFormat";
import convertToTime from "Components/Custom/TimeFormat";
import { editActivity } from "helpers/API Calls/Project/Activity/Activity";
import Select from "react-select"
import Badge from "../UI/Badge";

export const countWords = (html: string) => {
  // Remove HTML tags and sanitize the content
  const strippedString = DOMPurify.sanitize(html, { USE_PROFILES: { html: false } }).replace(/<[^>]*>?/gm, '');

  // Trim whitespace and ensure it's truly empty if there's no content
  const trimmedString = strippedString.trim();

  // If the string is empty, return 0 instead of 1
  if (trimmedString === '') {
    return 0;
  }

  // Split the string by one or more spaces, then filter out empty elements in case of extra spaces
  const wordArray = trimmedString.split(/\s+/).filter(word => word.length > 0);

  // Return the number of words
  return wordArray.length;
};

const ActivityDetail = ({ activityCreatedAt, activityCreatedBy, state, state_color, state_id, status, type, type_color, title, content, contentUpdatedAt, contentUpdatedBy, dueDate, team, canEdit, canArchive, canPublish, canManageLogs, canEditState,canManageVersions, activityStates, projectId,activityId, queries }) => {

  // Separate states for modal and action
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(''); // Can be 'archive', 'unarchive', 'showdetails', or 'updatestate', 
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<any>({label: state, value:state_id, color: state_color})
  const [updatedState, setUpdatedState] = useState<any>({label: state, value:state_id, color: state_color})

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(selectedState)
    setIsSubmitting(true)
    const data = {
      edit_type: "activity",
      project_id: projectId,
      activity_state_id: selectedState.value,
    };

    const response = await editActivity(data, activityId);
    if (response?.success) {
      closeModal(); 
      setUpdatedState(selectedState)
    }
    setIsSubmitting(false); 
  }

  // Open modal and set the action
  const openModal = (action: string) => {
    setCurrentAction(action);
    setIsModalOpen(true);
  };

  // Close modal and reset action
  const closeModal = () => {setIsModalOpen(false);};

  //update activity status -- Archive / Unarchive 
  const updateStatus = async () => {
    setIsSubmitting(true)
    
    const data = {
      edit_type: "activity",
      project_id: projectId,
      status: currentAction === 'archive' ? 'archieved' : 'active',
    };
    console.log(data)

    const response = await editActivity(data, activityId);
    if (response?.success) {
      closeModal(); 
      // setUpdatedState(selectedState)
    }
    setIsSubmitting(false); 
  }

  // details modal
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }

  const closeBtn = (
    <Button className="btn btn-soft-danger waves-effect waves-light" onClick={closeModal} type="button">
      <i className="mdi mdi-close-thick"></i>
    </Button>
  );

  return (
    <>
     {/* Modal */}
       <Modal isOpen={isModalOpen} toggle={closeModal} centered={true} size={currentAction === 'showdetails' ? "lg" : "md"} scrollable={currentAction === 'showdetails'}>
        <div className="modal-content">
          {currentAction === 'showdetails' ? (
            <>
              <ModalHeader toggle={closeModal} tag="h4" close={closeBtn}>{title}</ModalHeader>
              <ModalBody className="pb-4">
                <h5 className="font-size-21 pb-1">Description:</h5>
                {content ? (
                  <div className="preview" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
                ) : (
                  <p className="text-muted">No content available. Click the Edit button to add new content!</p>
                )}
              </ModalBody>
            </>
          ) : (
            <ModalBody className="px-4 py-5">
              <button type="button" onClick={closeModal} className="btn-close position-absolute end-0 top-0 m-3"></button>
              <div className="mb-4 mx-auto text-center">
                <div className="text-primary font-size-20">
                  {currentAction === 'archive'
                    ? 'Archive Activity'
                    : currentAction === 'unarchive'
                    ? 'Unarchive Activity'
                    : 'Update Activity State'}
                </div>
              </div>
              {currentAction !== 'updatestate' ? <div className="text-center">
              {currentAction === 'archive' && (<p className="text-muted font-size-16 mb-4">Are you sure you want to archive the Activity?</p>)}
              {currentAction === 'unarchive' && (<p className="text-muted font-size-16 mb-4">Are you sure you want to unarchive the Activity?</p>)}

              <div className="hstack gap-2 justify-content-center mb-0">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                <button type="button" className={`btn btn-${currentAction === 'archive' ? 'danger' : 'primary'}`} onClick={updateStatus}>
                  {currentAction === 'archive' ? 'Archive' : 'Unarchive'}
                </button>
              </div>
              </div>
              :
              <>
               <Form onSubmit={e => handleSubmit(e)}>
                <FormGroup>
                  <Label htmlFor="act-state">Activity State</Label>
                  <Select
                    id="act-state"
                    name="act-state"
                    className="border border-1 border bg-white"
                    options={activityStates} // Corrected here
                    onChange={(selectedOption: any) => {setSelectedState(selectedOption); }}
                    value={selectedState}
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999, // Ensure it's on top of the modal
                        backgroundColor: 'white', // Force white background
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f8f9fa' : 'white',
                        color: state.isSelected ? 'white' : 'black',
                      }),
                    }}
                  />
                </FormGroup>
                <div className="hstack gap-2 mb-0 justify-content-end">
                  <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                  <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Save"}
                  </button>
                </div>
              </Form>
              </>}

            </ModalBody>
          )}
        </div>
      </Modal>

      {/* title + buttons */}
      <Row className="my-0 py-0">
        <Col lg={8}>
          <div className="">
            <h3 className="text-truncate fw-bold ">{title}</h3>
          </div> 
        </Col>


        {/* Dropdown for actions */}
        {(canPublish || canArchive || canManageLogs || canEditState || canManageVersions) && (
          <Col lg={4} className="text-end">
            {canEditState && 
              <Button className="btn btn-info text-white"onClick={() => openModal('updatestate')}>Change State</Button>
            }
            {canPublish && 
              <LinkWithQuery queries={queries} project={true} to="publish" className="btn ms-2 btn-success text-white">
                Publish
              </LinkWithQuery>
            }
            <UncontrolledDropdown className="dropdown d-inline-block ms-2 mt-lg-1">
              <DropdownToggle type="menu" className="waves-effect btn btn-secondary" id="dropdownMenuButton1">
                <i className="mdi mdi-dots-vertical"></i>
              </DropdownToggle>
              <DropdownMenu>
                {canArchive && <li><DropdownItem onClick={() => openModal(status === 'active' ? 'archive' : 'unarchive')}>{status === 'active' ? 'Archive' : 'Un Archive'}</DropdownItem></li>}
                {canManageVersions && <li><LinkWithQuery queries={queries} project = {true} to="logs" ><DropdownItem>History</DropdownItem></LinkWithQuery></li>}
                {canManageLogs && <li><LinkWithQuery queries={queries} project = {true} to="history" ><DropdownItem>Activity</DropdownItem></LinkWithQuery></li>}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        )}

      </Row>

      {/* state and type and team*/}
      <div className="my-0 py-0 d-lg-flex d-md-block">
        <div className="mb-0 btn-link waves-effect me-3">
          <Badge color={type_color} title={type}/><span className="mx-2">in</span><Badge color={updatedState.color} title={updatedState.label}/>
        </div>
        <div className="d-flex">
          <span className="font-size-14 me-2 fw-bold">Team:</span>
          <p className="mb-0 btn-link waves-effect text-success">{team}</p>
        </div>
      </div>

      {/* assigned + due */}
      <div className="my-0 py-0 d-lg-flex d-md-block ">
          <div className="me-3">
            Assigned to <b>{activityCreatedBy}</b> on <b>{convertDateFormat(activityCreatedAt)}</b> <b>{convertToTime(activityCreatedAt)}</b>.
          </div>
          <div className="d-flex">
          <span className="font-size-14 me-2 fw-bold">Due on:</span>
            <p className="text-muted mb-0">{`${dueDate ? convertDateFormat(dueDate) : 'Not Specified'}`}.</p>
          </div>
      </div>

      {/* description */}
      <Row>
        <CardBody>
          <blockquote className="blockquote font-size-14 mb-0" >
            <div className="overflow-hidden mb-3" style={{ maxHeight: '100px' }}>
              {content ? (
                <div className="preview" dangerouslySetInnerHTML={createMarkup(content)} />
              ) : (
                canEdit ?
                  <>
                    <LinkWithQuery queries={queries} project={true} to="content-edit" className="text-success">Click here </LinkWithQuery>to add new content!
                  </>
                  :
                  <p className="text-muted" >No content available. </p>
                
              )}
            </div>
            <footer className="blockquote-footer my-0 font-size-12 d-flex align-items-center">
              <div>
                Updated by {contentUpdatedBy} on {convertDateFormat(contentUpdatedAt)} {convertToTime(contentUpdatedAt)}
              </div>
              <ul className="list-unstyled hstack gap-1 mb-0 ms-auto">

                <li data-bs-toggle="tooltip" data-bs-placement="top" title="View" onClick={() => {openModal('showdetails')}}>
                  <button className="btn btn-soft-info" >
                    {/* <i className="mdi mdi-eye-outline" id="view-tooltip"></i> */}
                    <CustomIcon className="mdi " iconName= "mdi-eye-outline" id="view-tooltip" text="View"/>
                  </button>
                </li>
                <UncontrolledTooltip placement="top" target="view-tooltip">View</UncontrolledTooltip>

                {canEdit && <>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                  <LinkWithQuery queries={queries} project={true} to="content-edit" className="btn btn-soft-primary">
                    <i className="mdi mdi-pencil-outline" id="edit-tooltip" />
                  </LinkWithQuery>
                </li>
                <UncontrolledTooltip placement="top" target="edit-tooltip">Edit</UncontrolledTooltip>
                </>}

              </ul>
            </footer>
            <footer className="blockquote-footer mt-0 font-size-12 d-flex">
            <div>
                Word Count:  <b>{countWords(content)}</b>
              </div>
            </footer>
          </blockquote>
        </CardBody>
        <div className="card-footer bg-transparent border-top text-muted">
          {/* <Row>
            <Col>
              <div>
                Word Count: <b>{countWords(content)}</b> Words
              </div>
            </Col>
          </Row> */}
        </div>
      </Row>
    </>
  );
};


export default ActivityDetail;
