import { toast } from "react-toastify";
import request from "../../../../request_helper";

export const getUsers = async (businessId : any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `users?limit=10000&business_id=${businessId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const createUser = async (userData) => {
    const requestData = JSON.stringify(userData)
    // console.log(requestData)
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "users",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : requestData,
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getUserDetail = async (Id: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `users/${Id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const editUser = async (permissionData, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "users/" + id,
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(permissionData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const deleteUser = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "users/" + id,
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return true; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return false; 
    }
}