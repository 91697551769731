const htmlToFormattedText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
  
    // Helper function to recursively process each node
    const processNode = (node) => {
      let text = '';
  
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          // Direct text content
          text += child.textContent;
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          switch (child.tagName) {
            case 'P':
              text += processNode(child) + '\n\n';  // Paragraphs with double line breaks
              break;
            case 'BR':
              text += '\n';  // Line break for <br> tags
              break;
            case 'LI':
              text += '• ' + processNode(child) + '\n';  // Bullet for list items
              break;
            case 'UL':
            case 'OL':
              text += processNode(child) + '\n';  // List with line breaks after
              break;
            case 'DIV':
              text += processNode(child) + '\n';  // Line break for <div> blocks
              break;
            case 'BLOCKQUOTE':
              text += '> ' + processNode(child) + '\n\n';  // Blockquote with indentation
              break;
            case 'H1':
            case 'H2':
            case 'H3':
            case 'H4':
            case 'H5':
            case 'H6':
              text += '\n' + child.textContent.toUpperCase() + '\n\n';  // Header with emphasis
              break;
            default:
              text += processNode(child);  // Default handling for other tags
          }
        }
      });
      return text;
    };
  
    // Apply processing to the entire document body
    return processNode(doc.body).trim();
  };
  
export default htmlToFormattedText; 