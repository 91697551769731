import React, { useState } from 'react'
import { Form, FormGroup, Label } from 'reactstrap';
import Select from "react-select"
import makeAnimated from 'react-select/animated';
import { editActivity } from 'helpers/API Calls/Project/Activity/Activity';

const animatedComponents = makeAnimated();


function Priority({selectedPriority, priorityOptions, id, canEdit}) {
  const [priority, setPriority] = useState<any>(selectedPriority); 
  const [isUpdating, setIsUpdating] = useState(false); 

  const handlePriorityChange = async (selectedOption: any) => {
    setPriority(selectedOption);
    setIsUpdating(true); 
    const data = {
      edit_type: "priority", 
      priority_id: selectedOption.value, 
    }
    const response = await editActivity(data, id); 
    if(response){setIsUpdating(false);}
  };

  return (
    <Form className="me-4 d-flex">
      <FormGroup className="select2-container d-flex" id='priority-form'>
        <Label className="form-label my-auto me-3" htmlFor='priority'>Priority</Label>
        <div className="flex-grow-1" style={{ minWidth: '125px' }}>
          <Select
            components={animatedComponents}
            value={priority}
            onChange={handlePriorityChange}
            isSearchable={true}
            name="priority"
            options={priorityOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            isDisabled={!canEdit}
            isLoading={isUpdating}
            
          />
        </div>
      </FormGroup>
    </Form>
  )
}

export default Priority