import React from "react";
import DataWrapper from "./DataWrapper";

function PaginatedCards({ onGetData, pagination, isLoading, children, buttonText = "", isAddButton, to='', isDeleted=false, title, query ='', isAddModal=false, onClick=()=>{}}) {

  return (
    <>    
    <DataWrapper 
      onGetData={onGetData}
      pagination={pagination}
      isLoading={isLoading}
      buttonText={buttonText}
      isAddButton={isAddButton}
      to= {to}
      isDeleted={isDeleted}
      title={title}
      sort={{sort_column: "id" , sort: "DESC"}}
      query= {query}
      isAddModal={isAddModal}
      onClick={onClick}
    >
      {children}
    </DataWrapper>
    </>
  );
}

export default PaginatedCards;
