import React from "react";
import classnames from "classnames";
import {Card, Collapse,} from "reactstrap";

function CollapseItem(props : any) {
  return (
    <Card className="px-0">
      <div className="accordion">
          <button
            className={classnames("cursor-pointer accordion-button rounded-top pb-0 pt-1", "fw-medium", {collapsed: !props.col,})} type="button" onClick={props.toggle}>
            <h5 className="card-header bg-transparent accordion-header">
              {props.title}
            </h5>
          </button>
      </div>
      <Collapse isOpen={props.col} className="accordion-collapse">
        {props.children}
      </Collapse>
    </Card>
  );
}

export default CollapseItem;
