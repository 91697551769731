import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import History from "../Activity History/History";
import { LinkWithQuery } from "helpers/link_helper";
import {Collapse,} from "reactstrap";
import classnames from "classnames";

const ActivityFeed = ({activity, returnURL}) => {
  const [collapse, setCollapse] = useState<boolean>(false)

  return (
    <React.Fragment>
      <Card>
        {/* <h5 className="text-truncate fw-bold font-size-21 pb-1">Activity:</h5> */}
        <div className="accordion">
          <button
            className={classnames("accordion-button  rounded-top", "fw-medium", {collapsed: !collapse})} type="button" onClick={() => {setCollapse(prev => !prev)}} style={{ cursor: "pointer" }}>
            <h5 className="fw-bold font-size-21 pb-1">Activity</h5>
          </button>
        </div>
        <Collapse isOpen={collapse} className="accordion-collapse">
          <CardBody className="bg-light rounded pt-4">
            {activity && activity.length > 0 ? (
              <>
                <ul className="verti-timeline list-unstyled">
                  {activity?.slice(0,5).map(item => 
                    <History key={item.timestamp} name={item?.name} timestamp={item.timestamp} action={item.action}/>
                  )}
                </ul>
                <div className="text-center mt-4">
                <LinkWithQuery queries={`return=${returnURL}`} project = {true} to="history" className="btn btn-success waves-effect waves-light btn-sm">
                    View More <i className="mdi mdi-arrow-right ms-1"></i>
                </LinkWithQuery>
                </div>
              </>
            ) : (
              <div className="text-center mt-4">
                <p className="text-danger">No activity has been performed yet.</p>
              </div>
            )}
          </CardBody>
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

export default ActivityFeed;
