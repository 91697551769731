import convertDateFormat from 'Components/Custom/DateFormat'
import convertToTime from 'Components/Custom/TimeFormat'
import React from 'react'
import { Col, Row } from 'reactstrap'
import Badge from './Badge';

interface HeaderProps {
    state :string;
    stateColor :string;
    type :string;
    typeColor :string;
    date :string;
    due :string;
    creator :string;
    team :string;
    title :string;
}
const Header: React.FC<HeaderProps> = ({ state, stateColor, type, typeColor, date, creator, team, title, due }) => {

  return (
    <>
      <Row>
        <Col lg={9}>
          <div className="">
            <h3 className="text-truncate fw-bold ">{title}</h3>
          </div> 
        </Col>
      </Row>

        {/* state and type and team*/}
      <div className="my-0 py-0 d-flex">
        <div className="mb-0 btn-link waves-effect me-3">
          <Badge color={typeColor} title={type}/><span className="mx-2">in</span><Badge color={stateColor} title={state}/>
        </div>
      
        <div className="d-flex">
          <span className="font-size-14 me-2 fw-bold">Team:</span>
          <p className="mb-0 btn-link waves-effect text-success">{team}</p>
        </div>
      </div>

      {/* assigned + due */}
      <div className="my-0 py-0 d-flex">
          <div className='me-3'>
            Assigned to <b>{creator}</b> on <b>{convertDateFormat(date)}</b> <b>{convertToTime(date)}</b>.
          </div>
          <div className="d-flex">
          <span className="font-size-14 me-2 fw-bold">Due on:</span>
            <p className="text-muted mb-0">{`${due ? convertDateFormat(due) : 'Not Specified'}`}.</p>
          </div>
      </div>
    </>
  )
}

export default Header