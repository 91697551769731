import { toast } from "react-toastify";
import request from "../../../request_helper";

export const getPublishStatuses = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "publish_statuses",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getPublishContentList = async (query: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `publish_contents?${query}&limit=1000`,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        };
        const response = await request(args); 
        const data = await response;
        return data.data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false, message: error.message}
    }
}

export const getPublishDetails = async (activityId: any, integrationId: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `publish_contents/show?activity_id=${activityId}&project_integration_id=${integrationId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const editPublishContent = async (reqData, hasToast = true) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "publish_contents" ,
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        const data = await response;
        hasToast && toast.success(response.message, { autoClose: 2000 });
        return data
    } catch (error: any) {
        hasToast &&  toast.error(error.message, { autoClose: 2000 });
        return {success: false, error: error.message}; 
    }
}

export const publishPost = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "publish",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false}; 
    }
}


export const schedulePost = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "schedule",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false}; 
    }
}

export const verifyPublishContent = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "publish_contents/bulk_verify",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        const data = await response;
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false}; 
    }
}

export const publishBulkContent = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "publish/bulk",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        const data = await response;
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
        return {success: false}; 
    }
}