import React, { useEffect, useMemo, useState } from 'react'
import { Card, Container, CardBody, Col, Form, FormGroup, Row, Button, Spinner, } from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import SocialIcon from "./UI/SocialIcon";
import ImageSelector from './Publish/Publish Details/Images/image-selector';
import WebsiteForm from './Publish/Publish Details/Platforms/WebsiteForm';
import SocialMediaForm from './Publish/Publish Details/Platforms/SocialMediaForm';
import { editPublishContent, getPublishDetails, publishPost } from 'helpers/API Calls/Project/Publish/Publish';
import { getActivityDetail } from 'helpers/API Calls/Project/Activity/Activity';
import Header from './UI/activity-header';
import { getAuthors, getCategories, getStatuses } from 'helpers/API Calls/Project/Publish/WordPress';
import { ToastContainer } from 'react-toastify';
import PublishModal from './Publish/Publish Details/Publish-Modal';
import htmlToFormattedText from 'Components/Custom/HtmlToText';

interface ImageData { id: number; src: string; type: 'image' | 'video';}

function EditSocialPost() {
    document.title = "Edit Publish Content | Content Pro";

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const businessId = searchParams.get("businessId");
    let projectId = searchParams.get('projectId');
    const returnURL = searchParams.get("return");
    const {id, platform} = useParams(); 
    const navigate = useNavigate(); 

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // form states 
    const [activity, setActivity] = useState<any>({}); //activity data and files
    const [publishData, setPublishData] = useState<any>({}); // saved publish content
    const [formOptions, setFormOptions] = useState<any>({}); //dropdown and input options
    const [formData, setFormData] = useState<any>({ author: {}, status: {}, category: {}, webLink: '', tags: '', content: ''}); //selected options and inputs
    const [isLoading, setIsLoading] = useState<boolean>(true); //loading
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [modalSelectedImages, setModalSelectedImages] = useState<ImageData[]>([]); //selected files 
    const [validationErrors, setValidationErrors] = useState({});

    // const [publishButton, setPublishButton] = useState<boolean>(false); 
    const [action, setAction] = useState<string>(""); 
    const [modal, setModal] = useState<boolean>(false);
    
    const fetchData = async () => {
      setIsLoading(true);

      const activityData = await getActivityDetail(id);
      if (activityData) {setActivity(activityData)}

      const data = await getPublishDetails(id, platform);
      if (data) {

        setPublishData(data);
        setModalSelectedImages(
          data?.publish_contents?.[0]?.publish_content_files?.map(item => 
            item?.file ? { id: item?.file?.id, src: item?.file?.file, type: item?.file?.file_type } : null
          )?.filter(Boolean) || []
        );
        
        const content = (!data?.publish_contents[0]) ? data?.content : data?.publish_contents[0]?.content;
        const integrationType = data?.project_integration?.integration_type_id;
        
        if(integrationType === 1){ // set form data for wordpress
          if (content) {
            try {
              if (content && content.trim()) {
                const blocksFromHTML = convertFromHTML(content);
                if (blocksFromHTML.contentBlocks && blocksFromHTML.contentBlocks.length > 0) {
                  const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                  );
                  setEditorState(EditorState.createWithContent(contentState));
                }
              } else {
                setEditorState(EditorState.createEmpty());
              }
            } catch (error) {
              console.error("Error converting HTML to EditorState:", error);
            }
          }
          const statuses = await getStatuses(); 
          const categories = await getCategories(platform); 
          const authors = await getAuthors(platform); 
          if(data?.publish_contents[0]?.publish_content_wordpress){
            const optionsData = data?.publish_contents[0]?.publish_content_wordpress
            const selectedAuthor = authors?.find(item => item?.id === optionsData?.author_id)
            const selectedStatus = statuses?.find(item => item?.id === optionsData?.status_id)
            const selectedCategory = categories?.find(item => item?.id === optionsData?.category_id)
            setFormData({ 
              author: {label: selectedAuthor?.name, value: selectedAuthor?.id}, 
              status: {label: selectedStatus?.status_name, value: selectedStatus?.id}, 
              category: {label: selectedCategory?.name, value: selectedCategory?.id}, 
            })
          }
          setFormOptions({
            statuses: (statuses || [])?.map(item => ({label: item?.status_name, value:item?.id})),
            categories: (categories || [])?.map(item => ({label: item?.name, value:item?.id})),
            authors: (authors || [])?.map(item => ({label: item?.name, value:item?.id})),
          })
        }else {
          const plainTextContent = !data?.publish_contents[0] ? htmlToFormattedText(content) : content; 
          const publishData = data?.publish_contents[0];
          if(publishData?.publish_content_facebook){
            setFormData({
              webLink: publishData?.publish_content_facebook?.web_link_url, 
              tags: publishData?.publish_content_facebook?.hashtags,
              content: plainTextContent,
            })
          }else{
            setFormData({content: plainTextContent,})
          }
        }
      }
      setIsLoading(false);
    };

    useEffect(() => {fetchData();}, []);

    const validateData = (currentAction) => {
      const type = publishData?.project_integration?.integration_type?.type_slug;
       // Helper functions for validation and data preparation
       const errors: any = {};
       if (type === "wordpress") {
         if (!formData.category.value) errors.category = 'Category is required';
         if (!formData.status.value) errors.status = 'Status is required';
         if (!formData.author.value) errors.author = 'Author is required';
        }else{
          // if (!formData.webLink) errors.webLink = 'Web Link is required';
          // if (!formData.tags) errors.tags = 'Tags are required';
          if (!formData.content) errors.content = 'Content is required';
       }
       if (Object.keys(errors).length > 0) {setValidationErrors(errors); return;}
       else{setModal(true); setAction(currentAction)}
    }

    const handleFormSubmit = async () => {
      const htmlContent = convertToHTML(editorState.getCurrentContent());
      const type = publishData?.project_integration?.integration_type?.type_slug;

      setValidationErrors({});    
      const prepareData = () => {
        const baseData = {
          activity_id: id,
          project_integration_id: platform,
          publish_status_id: action === 'Ready' ? 2 : 1,
          content: type === "wordpress" ? htmlContent : formData?.content,
          files: modalSelectedImages?.map(item => ({ file_id: item.id })),
          integration_type: type,
        };
    
        if (type === "wordpress") {
          return { ...baseData, wordpress: {status_id: formData?.status?.value, category_id: formData?.category?.value, author_id: formData?.author?.value,},};
        }
        else if (type === "facebook" || type === "facebook_page"){
          return { ...baseData, integration_type: "facebook", facebook: { web_link_url: formData?.webLink, hashtags: formData?.tags,} };
        }else{
          return { ...baseData, [type]: { web_link_url: formData?.webLink, hashtags: formData?.tags,} };
        }
    
        // const socialData = { web_link_url: formData?.webLink, hashtags: formData?.tags,};
    
        // if (type === "facebook" || type === "facebook_page") return { ...baseData, integration_type: "facebook", facebook: socialData };
        // if (type === "instagram") return { ...baseData, instagram: socialData };
        // if (type === "linkedin") return { ...baseData, linkedin: socialData };
    
        // return baseData;
      };
    
      setIsSubmitting(true);
      const data = prepareData();
      // console.log(data)
      // Handle submission based on action
      const actionMap = {
        'Draft': () => editPublishContent(data),
        'Edit': () => editPublishContent(data),
        'Ready': () => editPublishContent(data),
        'Publish': () => publishPost({ publish_content_id: publishData?.publish_contents[0]?.id }),
      };
    
      const response = await actionMap[action]();
      setIsSubmitting(false);
    
      if (response?.success) {
        fetchData();
        if (action === 'Publish') {
          setTimeout(() => navigate(`/posts-details/${id}/publish?return=${returnURL}&projectId=${projectId}&businessId=${businessId}`), 2000);
        }
      }
    };
    
   
    // DATES HANDLING IN MODAL FOR SCHEDULING
    // const dateInputRef = useRef<HTMLInputElement>(null);
    // const handleSchedule = (event) => {
    //   event.preventDefault();
    //   // Check if inputRef.current is not null before accessing its value
    //   if (dateInputRef.current) {
    //     const selectedDateTime = dateInputRef.current.value;
    //     console.log("Selected Date and Time:", selectedDateTime);
    //   } else {
    //     console.error("Input ref is null");
    //   }
    //   setModal(false);
    // };

    const isDisabled = useMemo(() => {
      return !(!publishData?.publish_contents?.[0] || publishData?.publish_contents?.[0]?.publish_status?.id === 1
        || publishData?.publish_contents?.[0]?.publish_status?.id === 8
      )
    }, [publishData]);

    const mediaType: [number, number] = useMemo(() => {
      const platform = publishData?.project_integration?.integration_type;
      
      // If the platform or its properties are undefined, default to 0
      const imagesLimit = platform?.publish_images_limit !== undefined ? platform.publish_images_limit : 0;
      const videosLimit = platform?.publish_videos_limit !== undefined ? platform.publish_videos_limit : 0;
    
      // Replace -1 with 1000 or another default value
      const finalImagesLimit = imagesLimit === -1 ? 1000 : imagesLimit;
      const finalVideosLimit = videosLimit === -1 ? 1000 : videosLimit;
    
      return [finalImagesLimit, finalVideosLimit];
    }, [publishData]);

  return (
    <>
      <PublishModal
        modal={modal} 
        setModal={setModal} 
        action={action} 
        handleFormSubmit={handleFormSubmit} 
        // dateInputRef={dateInputRef}
        // handleSchedule={handleSchedule}
      />

      <div className="page-content">
       <Container fluid>
         <Breadcrumbs title="Activity" breadcrumbItem="Edit Post" queries={`return=${returnURL}`}  isGoBackButton={true} project={true} to={`/posts-details/${id}/publish`}/>
         <Card>
          {isLoading ?
            <Spinner color="primary" className="position-absolute top-50 start-50" />
          :
            <CardBody>

              {/* activity header */}
              <Header 
                state={activity?.activity_state?.state_name || "N/A"}
                stateColor={activity?.activity_state?.color || "#ccc"}
                type={activity?.activity_types?.activity_type || "N/A"}
                typeColor={activity?.activity_types?.color || "#ccc"}
                date={activity?.created_at || "N/A"}
                due={activity?.due_date}
                creator={activity?.creator_name || "N/A"}
                team={activity?.team_name || ""}
                title={activity?.title || "Untitled"}
              />

              {/* Social Logo */}
              <Row>
                <Col className='mt-4'>
                  <div className="d-flex">
                    <SocialIcon 
                      title={publishData.project_integration?.integration_user || publishData?.project_integration?.access_token?.platform_username}
                      type={publishData?.project_integration?.integration_type?.type_name}
                      slug={publishData?.project_integration?.integration_type?.type_slug} 
                    />
                  </div>
                </Col>
              </Row>

              {/* Form for the submission */}
              <Form  onSubmit = {handleFormSubmit}>
                <FormGroup className="select2-container mt-4">

                  {publishData?.project_integration?.integration_type?.type_slug !== "wordpress" ? 
                    <SocialMediaForm formData={formData} setFormData={setFormData} validationErrors={validationErrors}
                    isDisabled={isDisabled}
                    />
                    :
                    <WebsiteForm formData={formData} setFormData={setFormData} formOptions={formOptions} validationErrors={validationErrors}
                    isDisabled={isDisabled} editorState={editorState} setEditorState={setEditorState}
                    />
                  }
                  
                  <ImageSelector files={activity?.files || []} modalSelectedImages={modalSelectedImages} setModalSelectedImages={setModalSelectedImages}
                    isDisabled={isDisabled} mediaType={mediaType}
                  />

                  {/* buttons */}
                  <Row className='mt-3'>
                    <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto">
                    {(!publishData?.publish_contents[0] || publishData?.publish_contents[0]?.publish_status?.id === 1 || publishData?.publish_contents[0]?.publish_status?.id === 8)
                      ?
                      <>
                        <Button onClick={() => {validateData("Ready")}} type="button" color="success" disabled={isSubmitting}>
                          {isSubmitting && action === 'Ready' ? 'Submitting...' : 'Ready for Publish'}
                        </Button>
                        <Button onClick={() => {validateData("Draft")}} type="button" color="success" disabled={isSubmitting}>
                          {isSubmitting && action === 'Draft' ? 'Updating...' : 'Save Changes'}
                        </Button>
                      </>
                      :
                      <>
                      {!(publishData?.publish_contents[0]?.publish_status?.id === 5) && 
                      <>
                        <Button onClick={() => {setModal(true); setAction("Publish")}} type="button" color="success" disabled={isSubmitting}>
                          {isSubmitting && action === 'Publish' ? 'Publishing...' : 'Publish Now'}
                        </Button>
                        <Button onClick={() => {setAction("Edit"); setModal(true);}} type="button" color="success" disabled={isSubmitting}>
                          {isSubmitting && action === 'Edit' ? 'Updating...' : 'Edit'}
                        </Button>
                      </>
                      }
                      </>
                     } 
                    </div>
                  </Row>

                </FormGroup>
              </Form>

            </CardBody>
          }
          </Card>
        </Container>
      </div>
      <ToastContainer/>
      {/* <Dropdown
      isOpen={publishButton}
      toggle={() => setPublishButton(prev => !prev)}
      >
      <DropdownToggle tag="button" type="button"  className="btn btn-success ">
        Publish <i className="mdi mdi-chevron-down font-size-16 align-middle" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => {setModal(true); setAction("Publish")}}><i className="mdi mdi-upload font-size-16 align-middle me-2"></i>Publish Now</DropdownItem>
        <DropdownItem onClick={() => {setModal(true); setAction("Schedule")}}><i className="mdi mdi-calendar-clock font-size-16 align-middle me-2"></i>Schedule For Later</DropdownItem>
      </DropdownMenu>
      </Dropdown> */}
    </>
  )
}

export default EditSocialPost
