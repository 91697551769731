import { createSlice } from "@reduxjs/toolkit";
import { getSubscriptions}  from "./thunk";

export const initialState = {
    subscriptionList: [], 
    loading: true,
    pagination: {current_page : 1, per_page : 10,}
}

const SubscriptionSlice = createSlice({
    name: "SubscriptionSlice", 
    initialState, 
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(getSubscriptions.fulfilled, (state: any, action: any) => {
            state.subscriptionList = action.payload.items;
            state.pagination = action.payload.pagination;
            // console.log(action.payload.pagination)
            state.loading = false; // Set loading to false when request is done
        });
        builder.addCase(getSubscriptions.pending, (state: any, action: any) => {
            state.subscriptionList = []; // reset any previous data while fetching new data
            state.loading = true; 
        });
        builder.addCase(getSubscriptions.rejected, (state: any, action: any) => {
            state.subscriptionList = []; // reset any previous data
            state.loading = false; 
        });
    }
    
})


export default SubscriptionSlice.reducer
