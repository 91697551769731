import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import { MentionsInput, Mention } from "react-mentions";
import convertDateFormat from "Components/Custom/DateFormat";
import convertToTime from "Components/Custom/TimeFormat";
import { addActivityComments } from "helpers/API Calls/Project/Activity/Activity";
import {Collapse,} from "reactstrap";
import classnames from "classnames";
import { highlightMentions } from "./Comments";

const AuthorNotes = ({ comments, user, users }) => {
  const { id } = useParams();

  const [text, setText] = useState("");
  const [formattedComments, setFormattedComments] = useState(comments || []);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [collapse, setCollapse] = useState<boolean>(false)

  // Handle adding a comment
  const handleAddComment = async () => {
    setIsSubmitting(true)
    const formattedText = text.replace(/@\[(.+?)\]\((\d+?)\)/g, "'@$1'");
    const data = {
      activity_id: id, 
      type: "notes", //discussion
      comment: formattedText, 
      comment_by: user.id,
    }
    const response = await addActivityComments(data); 
    if(response){setIsSubmitting(false)}
    if (response?.success) {
      setFormattedComments((prevComments) => [
        {
          id: new Date().toISOString(),
          user: { id: user.id, name: user.name, img: "" },
          comment: formattedText,
          updatedAt: new Date().toISOString(),
        },
        ...prevComments,
      ]);
      setText("");
    }
  };

  return (
    <Card>
      {/* <h5 className="text-truncate fw-bold font-size-21 pb-1">Notes:</h5> */}
      <div className="accordion">
        <button
          className={classnames("accordion-button rounded-top", "fw-medium", {collapsed: !collapse})} type="button" onClick={() => {setCollapse(prev => !prev)}} style={{ cursor: "pointer" }}>
          <h5 className="fw-bold font-size-21 pb-1">Notes</h5>
        </button>
      </div>
      <Collapse isOpen={collapse} className="accordion-collapse">
        <CardBody className="bg-light rounded">
          <div className="pb-3">
          <Row className="align-items-center g-2">
            <Col md={11} sm={9} id='tag-input-holder'>
              <MentionsInput
                id="tag-input"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="form-control border-0 rounded p-2"
                placeholder="Enter Notes..."
                singleLine={true}
              >
                <Mention
                  trigger="@"
                  data={users.map((user) => ({ id: user.id, display: user.name }))}
                  className = 'mention'
                  id='tag-text'
                />
              </MentionsInput>
            </Col>
            <Col sm="auto">
              <Button
                type="submit"
                color="success"
                className="w-100"
                onClick={handleAddComment}
                disabled={isSubmitting}
              >
                <i className="mdi mdi-send" />
              </Button>
            </Col>
          </Row>

          </div>

          {/* Notes */}
          <div>
            <div className="chat-conversation">
              <SimpleBar style={{ marginBottom: "1rem", maxHeight: "260px" }}>
                <ul className="list-unstyled">
                  {(formattedComments && formattedComments.length === 0) ? 
                  <p className="ms-1">No notes available. Please add your insights for the creator.</p>
                  :
                  formattedComments.map((comment) => (
                    <div className="d-flex mb-4" key={comment.id}>
                      <div className="me-3">
                        {comment.user?.img ? (
                          <img
                            className="media-object rounded-circle avatar-xs"
                            alt={comment.user?.name}
                            src={comment.user?.img}
                          />
                        ) : (
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-16">
                              {comment.user?.name.charAt(0)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-13 mb-1">
                          {comment.user?.name}
                          {comment.user?.id === user.id && <span className="text-muted"> (You)</span>}
                        </h5>
                        <small className="text-muted float-end">
                          {`${convertDateFormat(comment.updatedAt)} at ${convertToTime(comment.updatedAt)}`}
                        </small>
                        <p className="text-muted mb-1">{highlightMentions(comment.comment)}</p>
                      </div>
                    </div>
                  ))}
                </ul>
              </SimpleBar>
            </div>
          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default AuthorNotes;
