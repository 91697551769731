import { toast } from "react-toastify";
import request from "../../../../request_helper";

export const getPermissions = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "permissions?limit=100",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getRoles = async (businessId) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `roles?limit=1000&business_id=${businessId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const createRole = async (permissionData) => {
    const requestData = JSON.stringify(permissionData)

    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "roles",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : requestData,
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getRoleDetail = async (Id: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `roles/${Id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const editRole = async (permissionData, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "roles/" + id,
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(permissionData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const deleteRole = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "roles/" + id,
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}