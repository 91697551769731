import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row, FormFeedback, Spinner } from "reactstrap";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
// import DOMPurify from 'dompurify';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { editActivity, getActivityDetail } from "helpers/API Calls/Project/Activity/Activity";
import GoBackButton from "Components/Common/GoBackButton";
import { ToastContainer } from "react-toastify";
import CustomEditor from "Components/Custom/Editor";


const ActivityEdit = () => {
  document.title = "Edit Activity | Content Pro";
  const navigate = useNavigate(); 

  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  let projectId = searchParams.get('projectId');
  const returnURL = searchParams.get("return");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [convertedContent, setConvertedContent] = useState("");

  const validation = useFormik({
    initialValues: {
      activityName: '',
    },
    validationSchema: Yup.object().shape({
      activityName: Yup.string()
      .min(3, 'Activity Name must be at least 3 characters')
      .required("Please Enter Activity Name"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const htmlContent = convertToHTML(editorState.getCurrentContent());
      const data = {
        edit_type: 'content',
        title: values.activityName,
        content: htmlContent,
      };
      const response = await editActivity(data, id);
    
      if (response) {
        setSubmitting(false);
      }    
  
      if (response?.success) {
        setTimeout(() => { navigate(`/posts-details/${id}?return=${returnURL}&projectId=${projectId}&businessId=${businessId}`); }, 2000);
      }
    
      return;
    },
  });

  // Load activity data from API
useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    const data = await getActivityDetail(id); // Fetch the activity details

    if (data) {
      // Update form values
      validation.setValues({
        activityName: data?.title || "",
      });

      // Set editor state with fetched content
      if (data.content) {
        try {
          if (data.content && data.content.trim()) {
            const blocksFromHTML = convertFromHTML(data.content);
            if (blocksFromHTML.contentBlocks && blocksFromHTML.contentBlocks.length > 0) {
              const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
              );
              setEditorState(EditorState.createWithContent(contentState));
            }
          } else {
            setEditorState(EditorState.createEmpty());
          }
        } catch (error) {
          console.error("Error converting HTML to EditorState:", error);
        }
      }
    }
    setIsLoading(false); // Stop loading state
  };
  
  fetchData();
}, [id]);

  return (
    <React.Fragment>
      <ToastContainer/>
      <div className="page-content">
        <Container fluid>
        <GoBackButton to={`/posts-details/${id}`} queries={`return=${returnURL}`} project={true}/>
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <div className="text-center"><Spinner color="primary" /></div>
              ) : (
              <Card>
                <CardBody>
                  <Form
                    className="overflow-hidden"
                    id="edit-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col className="mx-auto">
                        <div className="mb-3">
                          <Label htmlFor="activityName">Activity Name</Label>
                          <Input
                            id="activityName"
                            name="activityName"
                            type="text"
                            placeholder="Enter Activity Name"
                            value={validation.values.activityName}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={!!(validation.touched.activityName && validation.errors.activityName)}
                          />
                          {validation.errors.activityName && validation.touched.activityName ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.activityName}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <CustomEditor editorState={editorState} setEditorState={setEditorState}/>

                    <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto px-1 mt-1">
                      <Button type="submit" color="success" disabled={validation.isSubmitting}>{validation.isSubmitting ? 'Saving...' : 'Save Changes'}</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ActivityEdit;
