import React, { useState } from 'react';
import { Card, Progress, CardBody, Col, Container, Row, CardTitle, CardText} from 'reactstrap';
import CollapseItem from 'pages/Dashboard/UI/CollapseItem';
import img6 from "assets/images/small/img-7.jpg";
import Activities from './Activity';
export interface ProgressType {
  id: number, color: string, label: string, percentage: number
}

const progressData: ProgressType[] = [
  { id: 1, color: "primary", label: "Pending", percentage: 78 },
  { id: 2, color: "warning", label: "In Progress", percentage: 69 },
  { id: 3, color: "success", label: "Completed", percentage: 61 }
];

const Dashboard = () => {

const [collapseStates, setCollapseStates] = useState({
    col1: true,
    col2: true,
  });

  const toggleCollapse = (col) => {
    setCollapseStates((prevState) => ({
      ...prevState,
      [col]: !prevState[col],
    }));
  };

  document.title = "Project | Content Pro";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>

            <Col lg={8}>

              <Activities/>

              <Row>
                <CollapseItem title = "Logs" col={collapseStates.col2} toggle={() => toggleCollapse("col2")}>
                  <div className="d-flex mx-3 border-bottom">
                    <div className="flex-shrink-0 my-auto ms-3">
                      {img6 ? 
                        <img src={img6} alt="" className="avatar-xs rounded-circle"/>
                        :
                        <div className="avatar-xs">
                          <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                            {('Brain')?.charAt(0)}
                          </div>
                        </div>
                      }
                      </div>
                    <div className="flex-grow-1">
                      <CardBody>
                        <CardTitle tag="h4">Brian</CardTitle>
                        <CardText className="text-muted">If several languages coalesce, the grammar of the resulting language.</CardText>
                      </CardBody>
                        {/* <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-success me-1"></i> 5 hrs ago</div> */}
                    </div>
                  </div>
                  <div className="d-flex mx-3 border-bottom">
                    <div className="flex-shrink-0 my-auto ms-3">
                      {img6 ? 
                        <img src={img6} alt="" className="avatar-xs rounded-circle"/>
                        :
                        <div className="avatar-xs">
                          <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                            {('Brain')?.charAt(0)}
                          </div>
                        </div>
                      }
                      </div>
                    <div className="flex-grow-1">
                      <CardBody>
                        <CardTitle tag="h4">Brian</CardTitle>
                        <CardText className="text-muted">If several languages coalesce, the grammar of the resulting language.</CardText>
                      </CardBody>
                        {/* <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-success me-1"></i> 5 hrs ago</div> */}
                    </div>
                  </div>
                  <div className="d-flex mx-3 border-bottom">
                    <div className="flex-shrink-0 my-auto ms-3">
                      {img6 ? 
                        <img src={img6} alt="" className="avatar-xs rounded-circle"/>
                        :
                        <div className="avatar-xs">
                          <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                            {('Brain')?.charAt(0)}
                          </div>
                        </div>
                      }
                      </div>
                    <div className="flex-grow-1">
                      <CardBody>
                        <CardTitle tag="h4">Brian</CardTitle>
                        <CardText className="text-muted">If several languages coalesce, the grammar of the resulting language.</CardText>
                      </CardBody>
                        {/* <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-success me-1"></i> 5 hrs ago</div> */}
                    </div>
                  </div>
                </CollapseItem>
              </Row>

            </Col>

            <Col lg={4}>

            <Card>
              <CardBody>
                <div className="d-flex flex-wrap align-items-start">
                  <div className="me-2">
                    <h5 className="card-title mb-3">Status</h5>
                  </div>
                </div>
                {/* <Row className="text-center">
                  <Col xs={6}>
                    <div className="mt-3">
                      <p className="text-muted mb-1">Today</p>
                      <h5>1024</h5>
                    </div>
                  </Col>

                  <Col xs={6}>
                    <div className="mt-3">
                      <p className="text-muted mb-1">This Month</p>
                      <h5>
                        12356{" "}
                        <span className="text-success font-size-13"> 0.2 % <i className="mdi mdi-arrow-up ms-1"></i></span>
                      </h5>
                    </div>
                  </Col>
                </Row> */}

                {/* <hr /> */}
                <div>
                  <ul className="list-group list-group-flush">
                    {(progressData || [])?.map((item: ProgressType, index: number) => (
                      <li className="list-group-item" key={index}>
                        <div className="py-2">
                          <h5 className="font-size-14">
                            {item.label} <span className="float-end">{item.percentage}%</span>
                          </h5>
                          <div className="progress animated-progess progress-sm">
                            <Progress
                              className={`progress-bar bg-${item.color}`}
                              value={78}
                              style={{ width: `${item.percentage}%` }}
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>

            <Card>
                <CardBody>
                  <CardTitle className="h4">Categories</CardTitle>
                  <p className="card-title-desc">
                    Include multiple progress bars in a progress component if
                    you need.
                  </p>
                  <div >
                    <Progress multi>
                      <Progress bar color="primary" value={40}></Progress>
                      <Progress bar color="success" value={30}></Progress>
                      <Progress bar color="danger" value={20}></Progress>
                    </Progress>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >

    </React.Fragment >
  );
}

export default Dashboard;