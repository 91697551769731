import React from "react";
import { useDrop } from "react-dnd";
import Task from "./Task";
import { Card, CardBody } from "reactstrap";

// Utility function to check if color is light or dark
export const isLightColor = (color: string) => {
  if(color){const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 155;}
};
interface TaskDetails {
  id: number;
  title: string;
  type: string;
  typeColor:string
  creator: string;
  team: string;
  state: string;
  stateColor:string
  priority: string;
  status: string;
  dueAt: string;
  createdAt: string;
  updatedAt: string;
  columnId: number;
}

interface ColumnProps {
  column: { id: number; title: string, color:string };
  tasks: TaskDetails[];
  moveTask: (taskId: number, toColumnId: number) => Promise<void>;
  deleteTask: (taskId: number) => Promise<void>;
}

const Column: React.FC<ColumnProps> = ({ column, tasks, moveTask, deleteTask }) => {

  const [, drop] = useDrop(() => ({accept: "TASK",drop: (item: { id: number }) => moveTask(item.id, column.id),}));

  return (
    <div ref={drop} className="mb-3 px-1 d-flex flex-column" style={{width: '270px'}}>
      <Card className="bg-light flex-grow-1">
        <CardBody className="d-flex flex-column p-0 text-center">
          <h4
            className="card-title mb-3 mx-0 board-column-heading rounded-top"
            style={{backgroundColor: column?.color,color: `${isLightColor(column.color) ? "black" : "white"}`,}}>
            {column.title}
          </h4>
          <div className="task-list flex-grow-1 text-start mx-2">
            {tasks.map((task) => (
              <div key={task.id} className="mb-2">
                <Task task={task} deleteTask={deleteTask}/>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Column;
