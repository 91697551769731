import React, {useEffect, useState } from "react";
import { Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import CustomIcon from "Components/Custom/CustomIcon";
import { LinkWithQuery } from "helpers/link_helper";
import PaginatedCards from "Components/Custom/Table and Cards/PaginatedCards";
import { useLocation } from "react-router-dom";
import { getRoles } from "slices/thunk";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from "react-toastify";
import { deleteRole } from "helpers/API Calls/Space Settings/Users Settings/Roles/Roles";

// redux code
const selectProperties = createSelector(
  (state: any) => state.roles,
  (roles) => ({
    roles: roles.roleList,
    loading: roles.loading,
    pagination: roles.pagination
  })
);

function Roles() {
  document.title = "Roles | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const { roles, loading, pagination } = useSelector(selectProperties);
  
  // local state mng for getting business data
  const [rolesData, setRolesData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(loading);
    setRolesData(roles);
  }, [roles, loading]);
  
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<any>(null);  
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const handleDelete = async () => {
    if (selectedRole) {
      const response = await deleteRole(selectedRole.id);
      setDeleteModal(false);
      setIsDeleted(response); 
      setSelectedRole(null); 
    }
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  return (
    <>

    <DeleteModal
      show={deleteModal}
      onDeleteClick={handleDelete}
      onCloseClick={() => {setSelectedRole(null); setDeleteModal(false)}}
      message={`Role ${selectedRole?.name}`}
    />

    <ToastContainer/>

    <div className="page-content">
      <Container fluid>
        <Card>
          <PaginatedCards 
            onGetData = {getRoles}
            pagination = {pagination}
            isLoading = {isLoading}
            isAddButton = {true} 
            to= {`create?businessId=${businessId}`}
            query = {`business_id=${businessId}`}
            buttonText="Add New Role"
            isDeleted = {isDeleted}
            title = 'Roles'
            >
              <Row>
              {rolesData.length !== 0 && rolesData.map(item => 
                  <Col lg={4} md={6} key={item.id}>
                    <div className="carousel-item active">
                      <div className="bg-light p-3 d-flex justify-content-between mb-3 rounded">
                        <h5 className="font-size-15 my-auto mb-2">{item.name}</h5>
                          <div className="my-auto text-end ">
                            <UncontrolledDropdown>
                              <DropdownToggle className="btn btn-soft-success" type="button" id="dropdownMenuButton11">
                                <CustomIcon className="bx " iconName="bx-dots-vertical-rounded" id={`action-${item.id}`} text="actions" />
                              </DropdownToggle>
                              <DropdownMenu aria-labelledby="dropdownMenuButton11" className="dropdown-menu-end">
                                <LinkWithQuery to={item.id}><DropdownItem>Edit</DropdownItem></LinkWithQuery>
                                {/* <LinkWithQuery to={item.id}><li><DropdownItem href={item.id}>Edit</DropdownItem></li></LinkWithQuery> */}
                                <li onClick={() => {setSelectedRole(item); setDeleteModal(true)}}><DropdownItem href="#">Delete</DropdownItem></li>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                    </div>
                  </Col>
                )}
                {rolesData.length === 0 && <div className="text-center"><p className="text-danger">No Data Found</p></div>}

              </Row>
          </PaginatedCards>
        </Card>
      </Container>
    </div>

    </>
  );
}

export default Roles;
