import React, { useState } from "react";
import { CardBody, CardText, CardTitle } from "reactstrap";
import CollapseItem from "./UI/CollapseItem";
import img6 from "../../assets/images/small/img-7.jpg";


function Backlogs() {
  const [collapseStates, setCollapseStates] = useState(true);
  return (
    <CollapseItem title="Backlogs" col={collapseStates} toggle={() => setCollapseStates((prevState) => !prevState)}>
      <div className="d-flex mx-3 border-bottom">
        <div className="flex-shrink-0 my-auto ms-3">
          {img6 ? 
            <img src={img6} alt="" className="avatar-xs rounded-circle"/>
            :
            <div className="avatar-xs">
              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                {('Brain')?.charAt(0)}
              </div>
            </div>
          }
          </div>
        <div className="flex-grow-1">
          <CardBody>
            <CardTitle tag="h4">Brian</CardTitle>
            <CardText className="text-muted">If several languages coalesce, the grammar of the resulting language.</CardText>
          </CardBody>
            {/* <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-success me-1"></i> 5 hrs ago</div> */}
        </div>
      </div>
      <div className="d-flex mx-3 border-bottom">
        <div className="flex-shrink-0 my-auto ms-3">
          {img6 ? 
            <img src={img6} alt="" className="avatar-xs rounded-circle"/>
            :
            <div className="avatar-xs">
              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                {('Brain')?.charAt(0)}
              </div>
            </div>
          }
          </div>
        <div className="flex-grow-1">
          <CardBody>
            <CardTitle tag="h4">Brian</CardTitle>
            <CardText className="text-muted">If several languages coalesce, the grammar of the resulting language.</CardText>
          </CardBody>
            {/* <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-success me-1"></i> 5 hrs ago</div> */}
        </div>
      </div>
      <div className="d-flex mx-3 border-bottom">
        <div className="flex-shrink-0 my-auto ms-3">
          {img6 ? 
            <img src={img6} alt="" className="avatar-xs rounded-circle"/>
            :
            <div className="avatar-xs">
              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                {('Brain')?.charAt(0)}
              </div>
            </div>
          }
          </div>
        <div className="flex-grow-1">
          <CardBody>
            <CardTitle tag="h4">Brian</CardTitle>
            <CardText className="text-muted">If several languages coalesce, the grammar of the resulting language.</CardText>
          </CardBody>
            {/* <div className="text-muted font-size-12"><i className="far fa-calendar-alt text-success me-1"></i> 5 hrs ago</div> */}
        </div>
      </div>
    </CollapseItem>
  );
}

export default Backlogs;
