import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import NotificationDropDown from "./NotificationDropDown";
import ProfileMenu from "./ProfileMenu";
import logo from "assets/images/logo-sm-light.png";
import logoDark from "assets/images/logo-large-light.png";
import { LinkWithQuery } from "helpers/link_helper";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import ProjectMenu from "./ProjectMenu";


const Header = (props: any) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleFullscreen = () => {
    let document: any = window.document;
    document.body.classList.add("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
    // handle fullscreen exit
    const exitHandler = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      )
        document.body.classList.remove("fullscreen-enable");
    };
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
  };

  const selectProperties = createSelector(
    (state: any) => state.user,
    (user) => ({user: user.user,})
  );
  const { user } = useSelector(selectProperties);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const shouldPreserveId = businessId !== null; 


  return (
    <React.Fragment>
      <header id="main-navigation">
        <div className="navbar-header">
          {/* logo */}
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="22" />
                </span>
              </Link>
            </div>

           {user.user_type === "Admin" && props.variant !== "admin" &&
            <div className={`me-3 my-auto ms-3 ${props.variant === "admin" && 'active'}`}>               
              <Link to="/admin" className="header-item noti-icon ">
                  <span className="d-block d-sm-none"><i className="fas fa-user-cog"></i></span>
                  <span className="d-none d-sm-block">Admin</span>
              </Link>
            </div>}

            {(props.variant !== "admin" && shouldPreserveId) && <>
            <div className={`my-auto ms-1 ${props.variant === "dashboard" && 'active'}`}>
              <LinkWithQuery to="/dashboard" className="header-item noti-icon ">
                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                <span className="d-none d-sm-block">Dashboard</span>
              </LinkWithQuery>
            </div>

            <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}  className="dropdown d-inline-block">

              <DropdownToggle className={`btn header-item position-relative d-flex flex-row drop-menu-icon noti-icon ${props.variant === "project" && 'active'}`} caret tag="button">
                <span className="d-block d-sm-none my-auto"><i className="bx bx-briefcase-alt-2"></i></span>
                <span className="d-none d-sm-block my-auto">Projects</span>
                <i className="mdi mdi-chevron-down d-none d-sm-block my-auto" />
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu dropdown-menu-lg  p-0">
                <ProjectMenu setDropdownOpen={setDropdownOpen}/>
              </DropdownMenu>

            </Dropdown>

            {(user.user_type !== "Member") && <div className={`my-auto ms-1 ${props.variant === "space-settings" && 'active'}`}>
              <LinkWithQuery to="/space" className="header-item noti-icon ">
                  <span className="d-block d-sm-none"><i className="fas fa-cogs"></i></span>
                  <span className="d-none d-sm-block">Space Setting</span>
              </LinkWithQuery>
            </div>}
            </>}
          </div>

          {/* right side items */}
          <div className="d-flex">
            {/* for full screen */}
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button type="button" onClick={() => { toggleFullscreen();}} className="btn header-item noti-icon " data-toggle="fullscreen">
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            {/* <NotificationDropDown/> */}
            <ProfileMenu variant={props.variant}/>
          </div>
          
        </div>
      </header>
    </React.Fragment>
  );
};


export default Header;