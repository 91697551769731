import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Modal, ModalBody, Input } from "reactstrap";
import CustomIcon from "Components/Custom/CustomIcon";
import PaginatedCards from "Components/Custom/Table and Cards/PaginatedCards";
import { useLocation } from "react-router-dom";
import { getRoles } from "slices/thunk";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from "react-toastify";
import { getActivityStates } from "helpers/API Calls/Project/Settings/Activity State";
import { editRole } from "helpers/API Calls/Space Settings/Users Settings/Roles/Roles";

// Redux code
const selectProperties = createSelector(
    (state: any) => state.roles,
    (roles) => ({
        roles: roles.roleList,
        loading: roles.loading,
        pagination: roles.pagination,
    })
);

function Roles() {
    document.title = "Role States | Content Pro";
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const businessId = searchParams.get("businessId");
    const projectId = searchParams.get("projectId");

    const { roles, loading, pagination } = useSelector(selectProperties);

    const [rolesData, setRolesData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    useEffect(() => {
        setIsLoading(loading);
        setRolesData(roles);
    }, [roles, loading]);

    const [allStates, setAllStates] = useState<any[]>([]);
    const [selectedRole, setSelectedRole] = useState<{ id?: string; name?: string; activity_states?: number[]; permissions?: number[] }>({
        name: '',
        id: '', 
        activity_states: [],
        permissions:[]
    });
    const [updatedModal, setUpdatedModal] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    useEffect(() => {
      if (isUpdated) {
        setIsUpdated(false);
      }
    }, [isUpdated]);

    useEffect(() => {
        async function fetchStates() {
            const states = await getActivityStates(projectId);
            setAllStates(
                states?.map((item) => ({
                    name: item.state_name,
                    id: item.id,
                    color: item.color,
                })) || []
            );
        }
        fetchStates();
    }, [projectId]);

    const handleEditClick = (role: any) => {
        setSelectedRole({
            name: role.name,
            activity_states: role.action_states?.map(item => item.id) || [],
            permissions: role.permissions?.map(item => item.id) || [],
            id: role.id
        });
        setUpdatedModal(true);
    };

    const handleStateChange = (stateId: number, checked: boolean) => {
      const updatedStates = checked
        ? [...(selectedRole.activity_states ?? []), stateId]  // Default to empty array
        : (selectedRole.activity_states ?? []).filter((id: number) => id !== stateId); // Default to empty array
    
      setSelectedRole((prevRole: any) => ({
        ...prevRole,
        activity_states: updatedStates,
      }));
    };

    const handleSubmit = async () => {
        setIsSubmitting(true); 
        const data = {
          name: selectedRole.name, 
          business_id: businessId, 
          permissions: selectedRole.permissions?.map(item => ({permission_id: item})),
          action_states: selectedRole.activity_states?.map(item => ({activity_state_id: item})),
        }
        console.log(data);
        const response = await editRole(data, selectedRole.id)
        if(response){setIsUpdated(true)}
        setUpdatedModal(false);
        setIsSubmitting(false); 
    };

    return (
        <>
            <ToastContainer />

            <div className="page-content">
                <Container fluid>
                    <Card>
                        <PaginatedCards
                            onGetData={getRoles}
                            pagination={pagination}
                            isLoading={isLoading}
                            isAddButton={false}
                            isDeleted={isUpdated}
                            query={`business_id=${businessId}`}
                            title="Roles"
                        >
                            <Row>
                                {rolesData.length !== 0 &&
                                    rolesData.map((item) => (
                                        <Col lg={4} md={6} key={item.id}>
                                            <div className="carousel-item active">
                                                <div className="bg-light p-3 d-flex justify-content-between mb-3 rounded">
                                                    <h5 className="font-size-15 my-auto mb-2">{item.name}</h5>
                                                    <div className="my-auto text-end">
                                                        <CustomIcon
                                                            className="btn btn-sm btn-soft-info mdi"
                                                            iconName="mdi-pencil"
                                                            id={`edit-${item.id}`}
                                                            text="Edit"
                                                            onClick={() => handleEditClick(item)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                {rolesData.length === 0 && (
                                    <div className="text-center">
                                        <p className="text-danger">No Data Found</p>
                                    </div>
                                )}
                            </Row>
                        </PaginatedCards>
                    </Card>
                </Container>
            </div>

            {/* Modal for editing role states */}
            <Modal isOpen={updatedModal} toggle={() => setUpdatedModal(false)} centered={true} backdrop={"static"}>
                <div className="modal-content">
                  <ModalBody className="px-4 pt-5">
                        <button
                            type="button"
                            onClick={() => setUpdatedModal(false)}
                            className="btn-close position-absolute end-0 top-0 m-3"
                        ></button>
                        <div className="mb-4 text-center font-size-20 text-primary">{selectedRole.name} States</div>
                        <h5 className="pb-2">Selected States</h5>
                        {allStates && allStates.length !==0 && allStates.map((state: any) => (
                            <div key={state.id} className="form-check mb-2">
                                <Input
                                    type="checkbox"
                                    id={`state-${state.id}`}
                                    checked={selectedRole?.activity_states?.includes(state.id) || false} // Safe check
                                    onChange={(e) => handleStateChange(state.id, e.target.checked)}
                                />
                                <label htmlFor={`state-${state.id}`} className="form-check-label">
                                    {state.name}
                                </label>
                            </div>
                        ))}
                        {allStates && allStates.length ===0 && <p className="text-danger">No States Found</p>}
                        <div className="hstack gap-2 mb-0 justify-content-end">
                            <button type="button" className="btn btn-secondary" onClick={() => setUpdatedModal(false)}>Close</button>
                            <button type="submit" className="btn btn-success" disabled={isSubmitting} onClick={handleSubmit}>
                                {isSubmitting ? "Submitting..." : "Save"}
                            </button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default Roles;
