import React, { useEffect, useState } from "react";
import withRouter from "Components/Common/withRouter";
import "flatpickr/dist/themes/material_blue.css";
import { Card, Container, FormGroup, Label, Modal, ModalBody, Form,} from "reactstrap";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import { LinkWithQuery } from "helpers/link_helper";
import { ToastContainer } from "react-toastify";
import CustomIcon from "Components/Custom/CustomIcon";
import { useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { editActivity } from "helpers/API Calls/Project/Activity/Activity";
import convertDateFormat from "Components/Custom/DateFormat";
import { getActivity } from "slices/thunk";
import { getActivityTypes } from "helpers/API Calls/Project/Settings/Activity Type";
import { getActivityStates } from "helpers/API Calls/Project/Settings/Activity State";
import convertToTime from "Components/Custom/TimeFormat";
import { getTeams } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";
import Select from "react-select"
import Badge from "./UI/Badge";

const selectProperties = createSelector(
  (state: any) => state.user,
  (state: any) => state.activity,
  (user, activity) => ({
    user: user.user,
    permissions: user.permissions,
    activity: activity.activityList,
    loading: activity.loading,
    pagination: activity.pagination
  })
);

const head = [
  {id: 1, name: "#", isSortable: true, key: "id"},
  {id: 2, name: "Activity", isSortable: true, key: "title"},
  {id: 4, name: "Creator", isSortable: true, key: "creator_name"},
  {id: 3, name: "Type", isSortable: true, key: "activity_type"},
  {id: 5, name: "Team", isSortable: true, key: "team_name"},
  {id: 7, name: "Priority", isSortable: true, key: "priority_name"},
  {id: 6, name: "State", isSortable: true, key: "state_name"},
  {id: 8, name: "Status", isSortable: true, key: "status"},
  {id: 9, name: "Due", isSortable: true, key: "due_date"},
  {id: 10, name: "Created", isSortable: true, key: "created_at"},
  {id: 11,name: "Updated", isSortable: true, key: "updated_at"},
  {id: 12,name: "Actions"}
]

const ActivityList = () => {
  document.title = "Activity List | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const projectId = searchParams.get("projectId");

  const { user, permissions, activity, loading, pagination } = useSelector(selectProperties);

  // local state mng for getting data
  const [activityData, setActivityData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    setIsLoading(loading);
    setActivityData(activity);
  }, [activity, loading]);

  const [filterData, setFilterData] = useState<any>({creators: [], types: [], states: [], teams: []}); 
  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const types = await getActivityTypes(projectId);
      const states = await getActivityStates(projectId);
      // console.log()
      const teams = await getTeams(businessId, `${user.user_type === 'Member' ? `user_id=${user.id}` : ''}`);

      const uniqueCreators = new Set();
      const teamMembers = teams?.reduce((acc:any, team:any) => {
        team.members.forEach(member => {
          if (!uniqueCreators.has(member.id)) {
            uniqueCreators.add(member.id);
            acc.push({ label: member.name, value: member.id });
          }
        });
        return acc;
      }, [] as any[]) || [];

      setFilterData({
        creators: teamMembers,
        types: types?.map(item => ({ label: item.activity_type, value: item.id })) || [],
        states: states?.map(item => ({ label: item.state_name, value: item.id })) || [],
        teams: teams?.map(item => ({ label: item.team_name, value: item.id, })) || [],
      });
      setIsLoading(false);
    }
    getData();
  }, [businessId, projectId, user]);

  // for deletion 
  // const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [modal, setModal]= useState<boolean>(false);
  const [activityItem, setActivityItem] =useState<any>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  // const handleDelete = async () => {
  //   if (activityItem) {
  //     const response = await deleteActivity(activityItem.id);
  //     setActivityItem(null);
  //     setDeleteModal(false);
  //     setIsDeleted(response); 
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true)
    const data = {
      edit_type: "activity",
      project_id: projectId,
      activity_state_id: activityItem.activity_state_id,
    };

    const response = await editActivity(data, activityItem.id);
    if (response?.success) {
      setIsDeleted(response?.success)
      setModal(false)
    }
    setIsSubmitting(false); 
  }

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const canAdd = user?.user_type === 'Member' ? permissions?.includes('create_post') : true;
  const canEditState = user?.user_type === 'Member' ? permissions?.includes('edit_post_state') : true;
  const canEdit = user?.user_type !== 'Member'
  // const canDelete = user?.user_type !== 'Member'

  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {setDeleteModal(false); setActivityItem({});}}
        message={`Activity ${activityItem?.title}`}
      /> */}

      <Modal isOpen={modal} toggle={() => {setModal(false); setActivityItem({})}} centered={true} backdrop={"static"}>
        <div className="modal-content">
          <ModalBody className="px-4 pt-5">
            <button type="button" onClick={() => {setModal(false); setActivityItem({})}} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 text-center font-size-20 text-primary">Update Activity State</div>
            <Form onSubmit={e => handleSubmit(e)}>
              <FormGroup>
                <Label htmlFor="act-state">Activity State</Label>
                <Select
                  id="act-state"
                  name="act-state"
                  className="border border-1 border bg-white"
                  options={filterData.states} // Corrected here
                  onChange={(selectedOption: any) => {setActivityItem(prev => ({ ...prev, activity_state_id: selectedOption.value })); }}
                  value={filterData.states.find(option => option.value === activityItem?.activity_state_id)}
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 9999, // Ensure it's on top of the modal
                      backgroundColor: 'white', // Force white background
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f8f9fa' : 'white',
                      color: state.isSelected ? 'white' : 'black',
                    }),
                  }}
                />
              </FormGroup>
              <div className="hstack gap-2 mb-0 justify-content-end">
                <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); setActivityItem({}); }}>Close</button>
                <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Save"}
                </button>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Card>
            <PaginatedTable
              tableHead={head}
              onGetData = {getActivity}
              pagination = {pagination}
              isLoading = {isLoading}
              buttonText = "Add New Activity" 
              isAddButton = {canAdd} 
              query = {`project_id=${projectId}`}
              to= {`/posts-create?return=posts-list&projectId=${projectId}&businessId=${businessId}`}
              isDeleted = {isDeleted}
              title = 'Activities List'
              hasDateFilter={true}
              hasFilter={true}
              filterData= {[
                { label: 'Types',key: 'activity_type_id',options: filterData.types}, 
                { label: 'States',key: 'activity_state_id',options: filterData.states}, 
                { label: 'Creators',key: 'creator_id',options: filterData.creators}, 
                { label: 'Teams',key: 'team_id',options: filterData.teams}, 
              ]}
              isActivityPage={true}
            >
              {activityData.length !== 0 && activityData.map((item : any, index : any) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>
                    <h5 className="font-size-14 mb-1" style={{ maxWidth: '150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      <LinkWithQuery to={`/posts-details/${item.id}`} project={true} className="text-success" queries={`return=posts-list`}>
                        {item.title}
                      </LinkWithQuery>
                    </h5>
                  </td>
                  <td>{item.creator_name}</td>
                  <td><Badge color={item.activity_types.color} title={item.activity_types.activity_type}/></td>
                  <td>{item.team_name}</td>
                  <td>{item.priority_name}</td>
                  <td><Badge color={item.activity_state.color} title={item.activity_state.state_name}/></td>
                  <td><span className={`font-size-11 badge badge-soft-${item.status === 'active' ? 'success' : 'danger'}`}>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</span></td>
                  <td><div>{convertDateFormat(item.due_date) || 'Not Specified'}</div></td>
                  <td>
                    <div>{convertDateFormat(item.created_at)}</div>
                    <div>{convertToTime(item.created_at)}</div>
                  </td>
                  <td>
                    <div>{convertDateFormat(item.updated_at)}</div>
                    <div>{convertToTime(item.updated_at)}</div>
                  </td>
                  <td className="d-flex">
                    <LinkWithQuery to={`/posts-details/${item.id}`} queries={`return=posts-list`} project={true} className="btn btn-sm btn-soft-primary me-1">
                      <CustomIcon className="mdi " iconName= "mdi-eye-outline" id={`view-${item.id}`} text="View"/>
                    </LinkWithQuery>
                    {canEdit && <LinkWithQuery to={`/posts-details/${item.id}/edit`} queries={`return=posts-list`} project={true} className="btn btn-sm btn-soft-info me-1">
                      <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                    </LinkWithQuery>}
                    {canEditState && <button className="btn btn-sm btn-soft-warning me-1" onClick={() => {setModal(true); setActivityItem(item);}} title="Update State">
                      <CustomIcon className="mdi" iconName="mdi-refresh" id={`update-${item.id}`} text="Update State" />
                    </button>}
                    {/* {canDelete && <button className="btn btn-sm btn-soft-danger" onClick={() => {setDeleteModal(true); setActivityItem(item);}} title="Delete Project">
                      <CustomIcon className="mdi " iconName= "mdi-delete-outline" id={`remove-${item.id}`} text="Remove"/>
                    </button>} */}
                    {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                      <DropdownToggle type="menu" className="btn btn-soft-secondary" id="dropdownMenuButton1"><i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                      <DropdownMenu>
                        <li>
                        <LinkWithQuery to={`/posts-details/${item.id}`} queries={`return=posts-list`} project={true}>
                          <DropdownItem>View Details</DropdownItem>
                        </LinkWithQuery>
                        </li>
                        {canEdit && 
                        <li>
                        <LinkWithQuery to={`/posts-details/${item.id}/edit`} queries={`return=posts-list`} project={true}>
                          <DropdownItem>Edit</DropdownItem>
                        </LinkWithQuery>
                        </li>
                        }
                        {canEditState &&
                        <li>
                          <DropdownItem onClick={() => {setModal(true); setActivityItem(item);}} >Update State</DropdownItem>
                        </li>
                        }
                        <li>
                        {canDelete && <DropdownItem onClick={() => {setDeleteModal(true); setActivityItem(item);}} title="Delete Project">
                          Delete
                        </DropdownItem>}
                        </li>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </td>
                </tr>
              ))}
              {activityData.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
            </PaginatedTable>
          </Card>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(ActivityList);
