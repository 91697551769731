import { toast } from "react-toastify";
import request from "../../../../request_helper";

export const getTeams = async (businessId, filter = "") => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `teams?limit=1000&business_id=${businessId}&${filter}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const createTeam = async (teamData) => {
    const requestData = JSON.stringify(teamData)
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "teams",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : requestData,
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const getTeamDetail = async (Id: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `teams/${Id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: 2000 });
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const editTeam = async (teamData, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "teams/" + id,
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(teamData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}

export const deleteTeam = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "teams/" + id,
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: 2000 });
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: 2000 });
    }
}