import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropzoneProps {
  accept?: { [key: string]: string[] };
  multiple?: boolean;
  disabled?: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  renderContent?: () => React.ReactNode;
}

const Dropzone: React.FC<DropzoneProps> = ({ accept = { "image/*": [".png", ".jpeg", ".jpg", ".gif"],"video/*": [".mp4", ".mov", ".avi"],}, multiple = false, disabled = false, onDrop,renderContent,}) => {
  
    const handleDrop = useCallback((acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;
      onDrop(acceptedFiles);
    },[onDrop]);

  const { getRootProps, getInputProps } = useDropzone({ accept, multiple, onDrop: handleDrop, disabled,});

  return (
    <div {...getRootProps()} className={`cursor-pointer ${disabled ? 'disabled' : ''}`}>
      <input {...getInputProps()} />
      {renderContent ? (
        renderContent()
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </div>
  );
};

export default Dropzone;
